import React, { FunctionComponent, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// import { FacebookProvider, LoginButton } from 'react-facebook';
import styles from "./Integration.module.css";
import { themeColors } from "../../Components/theme";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedIn from '@mui/icons-material/LinkedIn';
import FacebookLogin from "react-facebook-login";
import { FacebookProvider, LoginButton, Like } from 'react-facebook';
// import LinkedInPage from "../IntegrationLinkedin/IntegrationLinkedin";
import axios from "axios";
import {fetchApi} from "../../Services/organizations";
import FormDialogFB from "./DialogFB";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  showSnackbarAction,
  facebookUesrPageData
} from "../../Redux/actions";
import { Link } from 'react-router-dom';
import { Circles, Audio, BallTriangle } from 'react-loader-spinner';
import iconFacebook from '../../Assets/Images/fbicon.png'
import acresImages from  '../../Assets/Images/99acresImages.png'
import magicbricksicon from '../../Assets/Images/magicbricksicon.png'
import housingIcon from '../../Assets/Images/housingIcon.png'
import JDimages from '../../Assets/Images/JDimages.png'
import skh from '../../Assets/Images/skh.png'
import iconWeb from '../../Assets/Images/iconWeb.jpg'
import Loading from "../../Components/Loading/Loading";
import { fetchLeadsResources } from "../../Services/resources";

type props = {
  user: any;
  contacts: any;
  history: any;
  role: any;
  organizationUsers: any;
  teamLeadUsers: any;
  theme: any;
  facebookUesrPageList: any;
};
const loginButtonStyles = {
  backgroundColor: '#272944',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
};
const IntegrationFacebook: FunctionComponent<props> = ({
  user,
  contacts,
  history,
  role,
  organizationUsers,
  teamLeadUsers,
  theme,
  facebookUesrPageList
}) => {
  const dispatcher = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  const [API, setAPI] = useState<any[] | undefined>(undefined);
  const [showLoader, setShowLoader] = useState(true);
  const [filterActivePages, setFilterActivePages] = useState<any[] | undefined>(undefined);
  const [filterDeactivePages, setFilterDeactivePages] = useState<any[] | undefined>(undefined);
  const [filterAPI, setFilterAPI] = useState<any[] | undefined>(undefined);
  const [accessUserToken, setAccessUserToken] = React.useState('');
  const [rightOpen, setRightOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [userData, setUserData] = useState<any>();
  const [userPages, setUserPages] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [leadSourceList, setLeadSourceList] = useState<any[] | undefined>(
        undefined
      );
  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

useEffect(() => {
    if (user.organization_id) {
      const unsub = fetchLeadsResources(
        (data) => setLeadSourceList(data),
        user.organization_id
      );
      return () => {
        unsub();
      };
    }
  }, [user.organization_id]);
  
  useEffect(() => {
    if (user.organization_id) {
      fetchApi(user.organization_id, (val) => setAPI(val));
    }
  }, [user.organization_id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    loginStatusCheck();
    // getFbsslsValueFromApiTokenFB(user.organization_id);
  }, [])

  const loginStatusCheck = () => {
    window.FB.getLoginStatus(function (response: any) {
      if (response.status === 'connected') {
        setLoginStatus(true);
      } else if (response.status === 'not_authorized') {
        console.log('Not Authorized user');
      }
      else {
        console.log('user does not logged in to Facebook');
        // the user isn't logged in to Facebook.
      }
    });
  }
  // console.log("activeArr",activeArr);
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };

  const buttonStyle = {
    /* Add your custom styles here */
    backgroundColor: 'blue',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };
  const handleFaceBookConnect = () => {
    history.push("/facebook-leads")
  }
  const handle99AcersConnect = () => {
    history.push("/99acres", {data: leadSourceList });
  }
  const handleJustdialConnect = () => {
    history.push("/justdial",{data: leadSourceList })
  }
  const handleWebsiteConnect = () => {
    history.push("/website",{data: leadSourceList })
  }
  const handleSulekhaConnect = () => {
    history.push("/sulekha",{data: leadSourceList })
  }
  const handleHousingConnect = () => {
    history.push("/housing",{data: leadSourceList })
  }
  const handleMagicbricksConnect = () => {
    history.push("/magicbricks",{data: leadSourceList })
  }
  
  return (
    <>
      <div
        className={styles.parent}
        style={{ ...containerStyles, marginTop: "4%", overflow: "hidden", maxHeight: "500px", overflowX: "hidden", overflowY: "scroll", marginBottom: "20px" }}
      >
        {showLoader && <Loading/>}
        {/* outer block */}
        <div style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", margin: "20px 0px 20px 50px" }}>
          <h4>Integrations</h4>
          <div style={{ ...containerStyles, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", width: "87%", justifyContent: "flex-start", alignItems: "flex-start", height: "auto", paddingBottom: "50px", marginBottom: "50px" }}>
            {/* sub block */}
            <div style={{ width: "auto", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column" }}>
              <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "20px" }}>
                {/* Lead block */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}>
                    <div> <img src={iconFacebook}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>Facebook</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from your Facebook &amp; Lead Ads into
                    your Lead Rubix account.
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    {loginStatus === true ? <>
                      <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                        onClick={handleFaceBookConnect}>Connected
                        <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                      </a>
                    </> : <>
                      <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                        onClick={handleFaceBookConnect}>Configure
                        <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                      </a>
                    </>}
                  </div>
                </div>
                {/* new card2 */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}>
                    <div> <img src={acresImages}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>99acres</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from 99acres in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handle99AcersConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
              </div>
              <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "20px" }}>
                {/* Lead block */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",

                  }}>
                    <div> <img src={magicbricksicon}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>MagicBricks</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from MagicBricks in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleMagicbricksConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}>
                   <div> <img src={housingIcon}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>Housing.com</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from Housing.com in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleHousingConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
              </div>
              <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "20px" }}>
                {/* Lead block */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",

                  }}>
                    <div> <img src={JDimages}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>JustDial</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from JustDial in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleJustdialConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}>
                    <div> <img src={skh}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>Sulekha</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from Sulekha in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleSulekhaConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
              </div>
              <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "20px" }}>
                {/* Lead block */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",

                  }}>
                    <div> <img src={iconWeb}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>Website</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from your website’s contact form in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleWebsiteConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    theme: state.theme.isLightMode,
    facebookUesrPageList: state.faceBookUserPagesData.data,
  };
};
export default connect(mapStateToProps)(IntegrationFacebook);
