import React, { useState, useEffect, FunctionComponent } from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import {
  API_COLUMNS,
  ApiDataColumns,
  HOLIDAY_CONFIG_COLUMNS,
} from "../../Values/tables";
import {searchHoliday } from "../../Values/utils";
import CustomTable from "../../Components/CustomTable/CustomTable";
import ApiModal from "../../Components/Modals/ApiModal/ApiModal";
import {deleteHoliday, fetchHolidayFilter} from "../../Services/organizations";
import styles from "./HolidayConfiguration.module.css";
import { themeColors } from "../../Components/theme";
import Loading from "../../Components/Loading/Loading";
import { fetchLeadsResources } from "../../Services/resources";
import HolidayConfigModel from "../../Components/Modals/HolidayConfigModel/HolidayConfigModel";

type props = {
  history: any;
  organizationId: any;
  apiHeaders: any;
  theme: any;
};

const HolidayConfiguration: FunctionComponent<props> = ({
  history,
  organizationId,
  apiHeaders,
  theme,
}) => {
  const dispatcher = useDispatch();
  const [searchQuery, setsearchQuery] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [holidayModal, setHolidayModel] = useState(false);
  const [holidayData, setHolidayData] = useState<any[] | undefined>(undefined);
  const [status, setStatus] = useState("ALL");
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [leadSourceList, setLeadSourceList] = useState<any[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (organizationId) {
      fetchHolidayFilter(organizationId, (val) => setHolidayData(val));
    }
  }, [organizationId]);

  useEffect(() => {
    if (holidayData) {
      let data: any[] = [];
      holidayData.forEach((item) => {
        data.push(item);
      });
      setFilterData(data);
    }
  }, [holidayData]);

  useEffect(() => {
    if (holidayData) {
      const data = searchHoliday(holidayData, searchQuery);
      setTemporaryData(data);
      setFilterData(data);
    }
  }, [searchQuery, holidayData]);

  const topBarStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    width: "97%",
  };
  const parentStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
  };
  const handleDeleteHoliday=(data:any,index:any)=>{
    console.log("handleDeleteHoliday    ",data);
    if (window.confirm('Are you sure you want to delete this Holiday?')) {
        deleteHoliday(
            organizationId,
            data.holiday_id,
            dispatcher
          );
    }
  }
  return (
    <>
      <div
        className={styles.parent}
        style={{ display: "block", marginTop: "4%", ...parentStyles }}
      >
        <div
          className={commonStyle.topBar}
          style={{ marginTop: "0px", ...topBarStyles }}
        >
          {holidayData === undefined && <Loading />}
          <Topbar
            history={history}
            title={"Holiday Configuration"}
            path={""}
            onChange={(val) => setsearchQuery(val)}
            filterText="Status"
            setColumnModal={(data) => setColumnModal(data)}
            show={false}
            showStatusBox={true}
            setHolidayModel={(data) => setHolidayModel(data)}
            status={status}
          />
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
          <CustomTable
            tableColumns={HOLIDAY_CONFIG_COLUMNS}
            tableRows={filterData}
            selectedRows={selectedRows}
            setSelectedRows={(data) => setSelectedRows(data)}
            tableType="Holiday"
            showColumnModal={columnModal}
            hideColumnModal={() => setColumnModal(false)}
            selectedRowsData={selectedRowsData}
            setSelectedRowsData={(data) => setSelectedRowsData(data)}
            setSearchedItem={(val) => setsearchQuery(val)}
            setHolidayModal={(data) => setHolidayModel(data)}
            setHolidayEdit={(data) => setEdit(data)}
            setHolidayData={(data) => setEditData(data)}
            handleDeleteHoliday={handleDeleteHoliday}
          />
        </div>
      </div>
      {holidayModal && (
        <HolidayConfigModel
          open={holidayModal}
          close={() => setHolidayModel(false)}
        />
      )}
      {holidayModal === true && edit === true && (
        <HolidayConfigModel
            open={holidayModal}
            close={() => setHolidayModel(false)}
            edit={edit}
            editData={editData}
            editClose={() => setEdit(false)}
            holidayData={holidayData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
    apiHeaders: state.apiHeaderList.data,
    theme: state.theme.isLightMode,
  };
};
export default connect(mapStateToProps)(HolidayConfiguration);
