import React, { FunctionComponent, useState } from "react";
import styles from "./TopBar.module.css";
import { AiFillCaretDown } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import { FaChevronDown, FaFilter } from "react-icons/fa";

import StatusFilter from "../StatusFilter/StatusFilter";
import Import from "../../Components/Import/Import";
import { BsColumnsGap } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import { updateStage } from "../../Redux/actions";
import { useEffect } from "react";
import { correctLeadCount } from "../../Services/organizations";
import DistributionModel from "../../Components/Modals/DistributionModel/DistributionModel";
import { themeColors } from "../../Components/theme";
import Dropdown from "../DropDown/Dropdown";

type props = {
  user: any;
  history: any;
  //button title // some design functions are implemented based on differnet titles, like in API panel
  title: string;
  // button screen path
  path: string;
  onClick?: () => void;
  owner?: boolean;
  changeOwner?: () => void;
  // search value for panels
  onChange?: (text: any) => void;
  // status box
  setStatus?: (status: string) => void;
  // left button text change
  filterText: string;
  // for left most button and add button
  status?: string;
  taskFilter?: boolean;
  projectFilter?: boolean;
  // rightmost table column setter
  setColumnModal?: (data: boolean) => void;
  // setSmsModal?: (data: boolean) => void;
  // left button show and unshow condition
  show: boolean;
  // globally setted user role
  userRole: any;
  // only for leftmost button
  showStatusBox?: boolean;
  // dropdown exports only for contacts and users
  onExport?: () => void;
  onCallLogsImport?: () => void;
  onNotesImport?: () => void;
  onTasksImport?: () => void;
  setBulkEdit?: () => void;
  leadsStage: any;
  // setting fields to view for users with different profile
  setListViewModal?: (data: boolean) => void;
  // in api panel, news panel, to add api
  setApiModal?: (data: boolean) => void;
  setHolidayModel?: (data: boolean) => void;
  // not required, just for status types in left most button
  setApiFilter?: (data: string) => void;
  // not required
  apiFilterData?: string;
  // global state
  organizationId: string;
  deleteSelected?: () => void;
  setLoad?: (data: boolean) => void;
  // to enter lead ids for their state to be changed to fresh
  setChangeStageModal?: (data: boolean) => void;
  // select contact whose leads are to be deleted
  setDeleteRecordsModal?: (data: boolean) => void;
  // in custom button panel in admin panel
  setCustomButtonModal?: (data: boolean) => void;
  theme: any;
  toggleCondition?: any;
};

const optionApiDataFilter = [
  { "value": "7", "label": "7 Days" },
  { "value": "30", "label": "30 Days" },
  { "value": "all", "label": "Lifetime" }
];
const TopBar: FunctionComponent<props> = ({
  user,
  history,
  title,
  path,
  onClick,
  owner,
  changeOwner,
  onChange,
  setStatus,
  deleteSelected,
  filterText,
  status,
  taskFilter,
  projectFilter,
  setColumnModal,
  show,
  userRole,
  showStatusBox,
  onExport,
  onCallLogsImport,
  onNotesImport,
  onTasksImport,
  setBulkEdit,
  leadsStage,
  setListViewModal,
  setApiModal,
  setHolidayModel,
  setApiFilter,
  apiFilterData,
  organizationId,
  setLoad,
  setChangeStageModal,
  setDeleteRecordsModal,
  setCustomButtonModal,
  theme,
  toggleCondition
}) => {
  // left most box expand condition
  const [expand, setExpand] = useState(false);
  // dropdown expand condition
  const [expandImport, setExpandImport] = useState(false);
  // search icon on search bar change to cross on change
  const [value, setValue] = useState("");
  const [showApiFilter, setShowApiFilter] = useState(false);
  //  lead distribution panel open and close
  const [showDistributionModal, setShowDistributionModal] =
    useState(false);
  const dispatcher = useDispatch();
  const [apiFilterSelected, setApiFilterSelected] = useState("7")
  const [openSearchBox, SetOpenSearchBox] = useState(false);
  const onStageChange = (event: any, stage: string) => {
    if (event.target.checked) {
      dispatcher(updateStage({ [stage]: true }));
    } else if (!event.target.checked) {
      if (
        stage !== "FRESH" &&
        stage !== "INTERESTED" &&
        stage !== "CALLBACK"
      ) {
        dispatcher(updateStage({ [stage]: false }));
      }
    }
  };
  useEffect(() => {
    if (toggleCondition) {
      setValue('')
    }
  }, [toggleCondition])
  const apiFilter = (event: any) => {
    setApiFilterSelected(event);
    // console.log("event.target.checked",event.target.checked);
    if (setApiFilter) {
      setApiFilter(event);
    }
    // if (event.target.checked) {
    //   if (setApiFilter) {
    //     setApiFilter(event);
    //   }
    // } else {
    //   if (setApiFilter) {
    //     setApiFilter("");
    //   }
    // }
  };
  // api panel, 7 days , .....
  // const apiFilter = (event: any,filter:string) => {
  //   console.log("event.target.checked",event.target.checked);
  //   console.log("event.target.checked",filter);
  //   if (event.target.checked) {
  //     if (setApiFilter) {
  //       setApiFilter(filter);
  //     }
  //   } else {
  //     if (setApiFilter) {
  //       setApiFilter("");
  //     }
  //   }
  // };

  const textStyles = { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  return (
    <div className={styles.topBar}>
      {showStatusBox === true && title !== 'Add News Link' && (
        <>
          {userRole !== "Sales" && userRole === 'organization' && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {owner && (<div
                  className={styles.statusBox}
                  style={
                    title === "Add Contacts" ||
                      title === "drilldown"
                      ? { borderWidth: "0px" }
                      : {}
                  }
                >

                  <p
                    className={styles.owner}
                    onClick={changeOwner}
                  >
                    Change Owner
                  </p>
                </div>)}
                {owner && (
                  <div
                    className={styles.statusBox}
                    style={{
                      marginLeft: "10px",
                      borderWidth: "0px",
                    }}
                  >
                    <p
                      className={styles.owner}
                      onClick={setBulkEdit}
                    >
                      Edit Records
                    </p>
                  </div>
                )}
              </div>
              {/* {expand === true && (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "22px",
                    justifyContent: "center",
                  }}
                >
                  <StatusFilter
                    setStatus={(status) =>
                      setStatus && setStatus(status)
                    }
                    status={status}
                    taskFilter={taskFilter}
                    projectFilter={projectFilter}
                    setExpand={(data) => setExpand(data)}
                    expand={expand}
                  />
                </div>
              )} */}
            </div>
          )}
        </>
      )}

      {owner && (userRole === 'organization' && (
        <div
          className={styles.statusBox}
          style={{ marginLeft: '10px', borderWidth: '0px' }}
        >
          <h6
            className={styles.owner}
            onClick={() => {
              deleteSelected && deleteSelected();
            }}
          >
            Delete Records
          </h6>
        </div>
      ))}
      {owner && (userRole === 'superAdmin' && (
        <div
          className={styles.statusBox}
          style={{ marginLeft: '10px', borderWidth: '0px' }}
        >
          <h6
            className={styles.owner}
            onClick={() => {
              deleteSelected && deleteSelected();
            }}
          >
            Delete Records
          </h6>
        </div>
      ))}

      {/* {title === "Add User" && (
        <>
          <button
            style={{ marginLeft: "20px" }}
            className={styles.addBox}
            onClick={() => {
              setListViewModal && setListViewModal(true);
            }}
          >
            Set List View
          </button>
        </>
      )} */}



      {/* {title === "Add News Link" && (
        <button
          style={{ marginLeft: "20px" }}
          className={styles.addBox}
          onClick={() => {
            setApiModal && setApiModal(true);
          }}
        >
          Add News Link
        </button>
      )} */}

      {title === "Add Custom Button" && (
        <button
          style={{ marginLeft: "20px" }}
          className={styles.addBox}
          onClick={() => {
            setCustomButtonModal &&
              setCustomButtonModal(true);
          }}
        >
          Add Custom Buttom
        </button>
      )}

      {userRole !== "oganization" &&
        userRole !== "superAdmin" &&
        title === "Add Contacts" && (
          <>
            <div className={styles.leadContainer}>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>Fresh</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "FRESH");
                  }}
                  checked={leadsStage.FRESH}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>
                  Interested
                </p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "INTERESTED");
                  }}
                  checked={leadsStage.INTERESTED}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>Call Back</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "CALLBACK");
                  }}
                  checked={leadsStage["CALLBACK"]}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>Won</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "WON");
                  }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                  checked={leadsStage.WON}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>Lost</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "LOST");
                  }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                  checked={leadsStage.LOST}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>
                  Not Interested
                </p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "NOT INTERESTED");
                  }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                  checked={leadsStage["NOT INTERESTED"]}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>
                  Transfer Leads
                </p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "ALL");
                  }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                  checked={leadsStage["ALL"]}
                />
              </div>
            </div>
          </>
        )}

      {/* {title === "Add Api Data" && (
        <>
          <div className={styles.leadContainer}>
            <div className={styles.leadBox}>
              <p
                // className={styles.leadText}
                style={textStyles}
              >Last 7 Days</p>
              <input
                className={styles.checkbox}
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, "7");
                }}
                checked={
                  apiFilterData === "7" ? true : false
                }
              />
            </div>
            <div className={styles.leadBox}>
              <p
                // className={styles.leadText}
                style={textStyles}
              >
                Last 30 Days
              </p>
              <input
                className={styles.checkbox}
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, "30");
                }}
                checked={
                  apiFilterData === "30" ? true : false
                }
              />
            </div>
            <div className={styles.leadBox}>
              <p
                // className={styles.leadText}
                style={textStyles}
              >All</p>
              <input
                className={styles.checkbox}
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, "all");
                }}
                checked={
                  apiFilterData === "all" ? true : false
                }
              />
            </div>
          </div>
        </>
      )} */}

      <div className={styles.box}>
        {title !== "drilldown" &&
          title !== "Lead Distribution" &&
          title !== "Add Custom Button" &&
          title !== "Add Headers" && (
            <>
              {openSearchBox && (
                <div className={styles.searchBox}>
                  <input
                    className={styles.searchText}
                    placeholder="Search"
                    value={value}
                    onChange={(val) => {
                      onChange && onChange(val.target.value);
                      setValue(val.target.value);
                    }}
                  />
                  {/* {value.length !== 0 && (
                    <IoClose
                      color={"ff0000"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onChange && onChange("");
                        setValue("");
                        SetOpenSearchBox(false);
                      }}
                    />
                  )} */}
                </div>
              )}
              {openSearchBox ? <>
                <IoClose
                  color={"ff0000"}
                  style={{ cursor: "pointer", fontSize: "20px", width: "20px" }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                    SetOpenSearchBox(false);
                  }}
                />
              </> : <>
                <AiOutlineSearch style={{ cursor: "pointer", fontSize: "24px" }} onClick={() => SetOpenSearchBox(true)} color={"#000"} />
              </>}
            </>
          )}

        {title === "Add Api Data" && (
          <div style={{ width: "auto", marginLeft: "11px" }}>
            <select
              value={apiFilterSelected}
              style={{ height: "26px", width: "80px", borderRadius: "5px", cursor: "pointer" }}
              onChange={(e) => apiFilter(e.target.value)}
            >
              {optionApiDataFilter.map((option, index) => (
                <option key={index} value={option.value}> {/* Use option.value here */}
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {title === "Add Api" && (
          <button
            style={{ marginLeft: "20px" }}
            className={styles.addBox}
            onClick={() => {
              setApiModal && setApiModal(true);
            }}
          >
            <BsPlus size={22} color={"#ffffff"} />
            Add API
          </button>
        )}
         {title === "Holiday Configuration" && (
          <button
            style={{ marginLeft: "20px" }}
            className={styles.addBox}
            onClick={() => {
              setHolidayModel && setHolidayModel(true);
            }}
          >
            <BsPlus size={22} color={"#ffffff"} />
             Add Holiday Configuration
          </button>
        )}
        {title === "Add News Link" && (
          <button
            style={{ marginLeft: "20px" }}
            className={styles.addBox}
            onClick={() => {
              setApiModal && setApiModal(true);
            }}
          >
            <BsPlus size={22} color={"#ffffff"} />
            Add News Link
          </button>
        )}
        {title !== "Add Task" &&
          title !== "drilldown" &&
          title !== "Add Api" &&
          title !== "Add News Link" &&
          title !== "Add Api Data" &&
          title !== "Add Headers" &&
          title !== "Add Custom Button" &&
          title !== "All User List" &&
          title !== "View News" &&
          title !== "Lead Distribution" &&
          title !== "Holiday Configuration" &&
          (
            <button
              className={styles.addBox}
              onClick={() => {
                title === "Lead Distribution"
                  ? setShowDistributionModal(true)
                  : history.push(path);
              }}
            >
              <BsPlus size={22} color={"#ffffff"} />
              {title}
            </button>
          )}

        {title === "Add Headers" && (
          <button
            style={{ marginLeft: "50%" }}
            className={styles.addBox}
            onClick={() => {
              history.push(path);
              // title === "Lead Distribution"
              //   ? setShowDistributionModal(true)
              //   : history.push(path);
            }}
          >
            <BsPlus size={22} color={"#ffffff"} />
            {title}
          </button>
        )}
        {showDistributionModal && (
          <DistributionModel
            open={showDistributionModal}
            organization_id={user.organization_id}
            close={() => {
              setShowDistributionModal(false);
            }}
          />
        )}
        {show === true && title !== "drilldown" && (
          <>
            {(userRole === "Lead Manager" ||
              userRole === "organization" ||
              userRole === "Team Lead") && (
                <div
                  className={styles.arrowBox}
                  style={{ color: theme ? "#272944" : "white" }}
                  onClick={() =>
                    setExpandImport(!expandImport)
                  }
                >
                  <FaChevronDown />
                  {expandImport === true && (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "37px",
                      }}
                    >
                      <Import
                        onClick={onClick}
                        setExpandImport={(data) =>
                          setExpandImport(data)
                        }
                        onExport={onExport}
                        expand={expandImport}
                        close={() => setExpandImport(false)}
                        onCallLogsImport={onCallLogsImport}
                        onNotesImport={onNotesImport}
                        onTasksImport={onTasksImport}
                      />
                    </div>
                  )}
                </div>
              )}
          </>
        )}
        {true && (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '37px',
              // margin:"10px"
            }}
          ></div>
        )}
        {title !== "Add Custom Button" && (
          <div className={styles.columnManage} style={{ color: theme ? "#272944" : "white", margin: "10px" }}>
            <BsColumnsGap
              size={20}
              onClick={() => {
                setColumnModal && setColumnModal(true);
              }}
            />
            <span className={styles.tooltiptext}>
              Column Manager
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    userRole: state.user.role,
    leadsStage: state.leadsStage.stage,
    organizationId: state.organization.id,
    theme: state.theme.isLightMode
  };
};

export default connect(mapStateToProps)(TopBar);