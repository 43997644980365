import { firestore, storage } from '../Firebase';
import Firebase from 'firebase/app';

import { setCompleted, setTotal, showSnackbarAction } from '../Redux/actions';
import moment from 'moment';
import {
  CompletedTaskValidation,
  OverdueTaskValidation,
  PendingTaskValidation,
} from './validation';
import { chunk, properFormat } from '../Values/utils';

export const uploadCarouselImages = async (
  imageList: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  close: () => void
) => {
  var carouselList: any[] = [];
  for await (const result of Array.from(imageList).map(async (item: any) => {
    const path = `carousel/${organization_id}/${item.name}`;
    const reference = storage.ref(path);
    await reference.put(item);
    const url = await reference.getDownloadURL();
    carouselList.push({
      url,
      created_at: Firebase.firestore.Timestamp.now(),
      image_name: item.name,
    });
    return true;
  })) {
    if (result) {
      createCarousel(organization_id, carouselList, dispatcher, setLoad, close);
    }
  }
};

const createCarousel = (
  organization_id: string,
  carouselList: any[],
  dispatcher: any,
  setLoad: (data: boolean) => void,
  close: () => void
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        carousel: Firebase.firestore.FieldValue.arrayUnion(...carouselList),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Carousel Updated!', 'success'));
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log('Carousel Error', error);
      dispatcher(showSnackbarAction('Error!Try Again', 'error'));
      setLoad(false);
    });
};

export const fetchResources = (
  setLocations: (data: any[]) => void,
  setBudgets: (data: any[]) => void,
  setCarousel: (data: any[]) => void,
  setLeadSource: (data: any[]) => void,
  setComType: (data: any[]) => void,
  setResType: (data: any[]) => void,
  organization_id: string,
  setTransferReasons?: (data: any[]) => void,
  setPropertyTypes?: (data: any[]) => void,
  setPropertyStage?: (data: any[]) => void,
  setPropertySubType?: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection('organizationResources')
    .doc(organization_id)
    .onSnapshot((resources) => {
      if (resources) {
        const resourceData = resources.data();
        if (resourceData?.locations) {
          setLocations(resourceData?.locations);
        } else {
          setLocations([]);
        }
        if (resourceData?.budgets) {
          setBudgets(resourceData?.budgets);
        } else {
          setBudgets([]);
        }
        if (resourceData?.leadSources) {
          setLeadSource(resourceData?.leadSources);
        } else {
          setLeadSource([]);
        }
        if (resourceData?.carousel) {
          setCarousel(resourceData?.carousel);
        } else {
          setCarousel([]);
        }
        if (resourceData?.comTypes) {
          setComType(resourceData?.comTypes);
        } else {
          setComType([]);
        }
        if (resourceData?.resTypes) {
          setResType(resourceData?.resTypes);
        } else {
          setResType([]);
        }
        if (resourceData?.TransferReasons) {
          setTransferReasons &&
            setTransferReasons(resourceData?.TransferReasons);
        } else {
          setTransferReasons && setTransferReasons([]);
        }
        if (resourceData?.PropertyTypes) {
          setPropertyTypes &&
          setPropertyTypes(resourceData?.PropertyTypes);
        } else {
          setPropertyTypes && setPropertyTypes([]);
        }
        if (resourceData?.PropertyStages) {
          setPropertyStage &&
          setPropertyStage(resourceData?.PropertyStages);
        } else {
          setPropertyStage && setPropertyStage([]);
        }
        if (resourceData?.PropertySubTypes) {
          setPropertySubType &&
          setPropertySubType(resourceData?.PropertySubTypes);
        } else {
          setPropertySubType && setPropertySubType([]);
        }
      } else {
        console.log('Org not found');
      }
    });
  return subscriber;
};

export const fetchLeadsResources = (
  setLeadSource: (data: any[]) => void,
  organization_id: string,
) => {
  const subscriber = firestore
    .collection('organizationResources')
    .doc(organization_id)
    .onSnapshot((resources) => {
      if (resources) {
        const resourceData = resources.data();
        if (resourceData?.leadSources) {
          setLeadSource(resourceData?.leadSources);
        } else {
          setLeadSource([]);
        }
      } else {
        console.log('Org not found');
      }
    });
  return subscriber;
};

export const createLocation = (
  organization_id: string,
  location_name: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        locations: Firebase.firestore.FieldValue.arrayUnion({
          location_name: location_name,
          created_at: Firebase.firestore.Timestamp.now(),
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Location Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Location Error', error);
      setLoad(false);
    });
};

export const createLocationAuto =async (
  organization_id: any,
  locationsList: any,
) => {
  const resLocationRef = firestore.collection('organizationResources').doc(organization_id);
  try {
    for (const locations of locationsList) {
      await resLocationRef.set(
        {
          locations: Firebase.firestore.FieldValue.arrayUnion(locations)
        },
        { merge: true }
      );
    }
    console.log("locations Add Successfully");
  } catch (error) {
    console.log('locations Error', error);
  }
};

export const createBudgetAuto =async (
  organization_id: any,
  budgetsList: any,
) => {
  const resBudgetsRef = firestore.collection('organizationResources').doc(organization_id);
  try {
    for (const budgets of budgetsList) {
      await resBudgetsRef.set(
        {
          budgets: Firebase.firestore.FieldValue.arrayUnion(budgets)
        },
        { merge: true }
      );
    }
    console.log("budgets Add Successfully");
  } catch (error) {
    console.log('budgets Error', error);
  }
};

export const createCurouseAuto =async (
  organization_id: any,
  carouselList: any,
) => {
  const rescarouselRef = firestore.collection('organizationResources').doc(organization_id);
  try {
    for (const carousel of carouselList) {
      await rescarouselRef.set(
        {
          carousel: Firebase.firestore.FieldValue.arrayUnion(carousel)
        },
        { merge: true }
      );
    }
    console.log("carousel Add Successfully");
  } catch (error) {
    console.log('carousel Error', error);
  }
};

export const createLeadSourceAuto =async (
  organization_id: any,
  leadSourceList: any,
) => {
  const resleadSourcesRef = firestore.collection('organizationResources').doc(organization_id);
  try {
    for (const leadSources of leadSourceList) {
      await resleadSourcesRef.set(
        {
          leadSources: Firebase.firestore.FieldValue.arrayUnion(leadSources)
        },
        { merge: true }
      );
    }
    console.log("leadSources Add Successfully");
  } catch (error) {
    console.log('leadSources Error', error);
  }
};
export const createPropertyStageAuto =async (
  organization_id: any,
  propertyStage: any,
) => {
  const resPropertyStagesRef = firestore.collection('organizationResources').doc(organization_id);
  try {
    for (const PropertyStages of propertyStage) {
      await resPropertyStagesRef.set(
        {
          PropertyStages: Firebase.firestore.FieldValue.arrayUnion(PropertyStages)
        },
        { merge: true }
      );
    }
    console.log("PropertyStages Add Successfully");
  } catch (error) {
    console.log('PropertyStages Error', error);
  }
};
export const createPropertyTypesAuto =async (
  organization_id: any,
  propertyTypes: any,
) => {
  const resPropertyTypesRef = firestore.collection('organizationResources').doc(organization_id);
  try {
    for (const PropertyTypes of propertyTypes) {
      await resPropertyTypesRef.set(
        {
          PropertyTypes: Firebase.firestore.FieldValue.arrayUnion(PropertyTypes)
        },
        { merge: true }
      );
    }
    console.log("PropertyTypes Add Successfully");
  } catch (error) {
    console.log('PropertyTypes Error', error);
  }
};

export const createPropertySubTYpeAuto =async (
  organization_id: any,
  propertySubType: any,
) => {
  const resPropertySubTypesRef = firestore.collection('organizationResources').doc(organization_id);
  try {
    for (const PropertySubTypes of propertySubType) {
      await resPropertySubTypesRef.set(
        {
          PropertySubTypes: Firebase.firestore.FieldValue.arrayUnion(PropertySubTypes)
        },
        { merge: true }
      );
    }
    console.log("PropertySubTypes Add Successfully");
  } catch (error) {
    console.log('PropertySubTypes Error', error);
  }
};
export const createTrasferReasonsAuto =async (
  organization_id: any,
  transferReasons: any,
) => {
  const resTransferReasonsRef = firestore.collection('organizationResources').doc(organization_id);
  try {
    for (const TransferReasons of transferReasons) {
      await resTransferReasonsRef.set(
        {
          TransferReasons: Firebase.firestore.FieldValue.arrayUnion(TransferReasons)
        },
        { merge: true }
      );
    }
    console.log("TransferReasons Add Successfully");
  } catch (error) {
    console.log('TransferReasons Error', error);
  }
};

export const createBudget = (
  organization_id: string,
  budget: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        budgets: Firebase.firestore.FieldValue.arrayUnion({
          budget: budget,
          created_at: Firebase.firestore.Timestamp.now(),
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Budget Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Budget Error', error);
      setLoad(false);
    });
};

export const createLeadSource = (
  organization_id: string,
  leadSource: string,
  leadSourceColor: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void,
  leadSource_id?:any
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        leadSources: Firebase.firestore.FieldValue.arrayUnion({
          leadSource: leadSource,
          leadSourceColor:leadSourceColor,
          created_at: Firebase.firestore.Timestamp.now(),
          leadSource_id:leadSource_id
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Lead Source Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Lead Source Error', error);
      setLoad(false);
    });
};

export const deleteProjectImage = async (
  type: string,
  imageList: any[] | undefined,
  project_id: any,
  index: number,
  fileName: string,
  dispatcher: any
) => {
  const path = `project/${project_id}/${type}/${fileName}`;
  const reference = storage.ref(path);
  imageList?.splice(index, 1);
  try {
    await firestore.collection('projectResources').doc(project_id).set(
      {
        images: imageList,
      },
      { merge: true }
    );

    await reference.delete();
    dispatcher(showSnackbarAction('Image Deleted', 'success'));
  } catch (e) {
    console.log('Error:', e);
    dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
  }
};

export const deleteProjectVideo = async (
  type: string,
  videoList: any[] | undefined,
  project_id: any,
  index: number,
  fileName: string,
  dispatcher: any
) => {
  const path = `project/${project_id}/${type}/${fileName}`;
  const reference = storage.ref(path);
  videoList?.splice(index, 1);
  try {
    await firestore.collection('projectResources').doc(project_id).set(
      {
        video: videoList,
      },
      { merge: true }
    );

    await reference.delete();
    dispatcher(showSnackbarAction('Video Deleted', 'success'));
  } catch (e) {
    console.log('Error:', e);
    dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
  }
};

export const deleteProjectBrochures = async (
  type: string,
  brochuresList: any[] | undefined,
  project_id: any,
  index: number,
  fileName: string,
  dispatcher: any
) => {
  const path = `project/${project_id}/${type}/${fileName}`;
  const reference = storage.ref(path);
  brochuresList?.splice(index, 1);
  try {
    await firestore.collection('projectResources').doc(project_id).set(
      {
        brochures: brochuresList,
      },
      { merge: true }
    );

    await reference.delete();
    dispatcher(showSnackbarAction('brochure Deleted', 'success'));
  } catch (e) {
    console.log('Error:', e);
    dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
  }
};
export const deleteProjectPriceLists = async (
  type: string,
  priceList: any[] | undefined,
  project_id: any,
  index: number,
  fileName: string,
  dispatcher: any
) => {
  const path = `project/${project_id}/${type}/${fileName}`;
  const reference = storage.ref(path);
  priceList?.splice(index, 1);
  try {
    await firestore.collection('projectResources').doc(project_id).set(
      {
        priceLists: priceList,
      },
      { merge: true }
    );

    await reference.delete();
    dispatcher(showSnackbarAction('priceList Deleted', 'success'));
  } catch (e) {
    console.log('Error:', e);
    dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
  }
};
export const deleteProjectLayouts = async (
  type: string,
  layoutList: any[] | undefined,
  project_id: any,
  index: number,
  fileName: string,
  dispatcher: any
) => {
  const path = `project/${project_id}/${type}/${fileName}`;
  const reference = storage.ref(path);
  layoutList?.splice(index, 1);
  try {
    await firestore.collection('projectResources').doc(project_id).set(
      {
        layouts: layoutList,
      },
      { merge: true }
    );

    await reference.delete();
    dispatcher(showSnackbarAction('layout Deleted', 'success'));
  } catch (e) {
    console.log('Error:', e);
    dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
  }
};

export const deleteProjectForms = async (
  type: string,
  formList: any[] | undefined,
  project_id: any,
  index: number,
  fileName: string,
  dispatcher: any
) => {
  const path = `project/${project_id}/${type}/${fileName}`;
  const reference = storage.ref(path);
  formList?.splice(index, 1);
  try {
    await firestore.collection('projectResources').doc(project_id).set(
      {
        forms: formList,
      },
      { merge: true }
    );

    await reference.delete();
    dispatcher(showSnackbarAction('layout Deleted', 'success'));
  } catch (e) {
    console.log('Error:', e);
    dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
  }
};

export const deleteCarouselImage = async (
  organization_id: string,
  image_name: string,
  carouselList: any[],
  index: number,
  dispatcher: any
) => {
  const path = `carousel/${organization_id}/${image_name}`;
  const reference = storage.ref(path);
  carouselList.splice(index, 1);
  try {
    await firestore
      .collection('organizationResources')
      .doc(organization_id)
      .set(
        {
          carousel: carouselList,
        },
        { merge: true }
      );

    await reference.delete();
    dispatcher(showSnackbarAction('Carousel Updated', 'success'));
  } catch (e) {
    console.log('Error:', e);
    dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
  }
};

export const deleteLocation = async (
  location: string,
  organization_id: string,
  locationList: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  let data: any[] = [];
  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('location', '==', location)
    .get()
    .then((contact) => {
      if (contact) {
        contact.forEach((item) => {
          data.push(item.data());
        });
      }

      if (data.length !== 0) {
        dispatcher(
          showSnackbarAction('This Location Is Used In Some Contacts', 'error')
        );
        setLoad(false);
      } else {
        locationList.splice(index, 1);
        try {
          firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                locations: locationList,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Location Deleted', 'success'));
          setLoad(false);
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
      }
    });
};

export const deleteComType = async (
  commercial: string,
  organization_id: string,
  commList: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  let data: any[] = [];
  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('property_sub_type', '==', commercial)
    .get()
    .then((contact) => {
      if (contact) {
        contact.forEach((item) => {
          data.push(item.data());
        });
      }

      if (data.length !== 0) {
        dispatcher(
          showSnackbarAction(
            'This Commercial Type Is Used In Some Contacts',
            'error'
          )
        );
        setLoad(false);
      } else {
        commList.splice(index, 1);
        try {
          firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                comTypes: commList,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Commercial Type Deleted', 'success'));
          setLoad(false);
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
      }
    });
};

export const deleteTransferReasonProcess = async (
  transferReason: string,
  organization_id: string,
  transferReasons: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
      transferReasons.splice(index, 1);
        try {
          firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                TransferReasons: transferReasons,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Transfer Reason Deleted', 'success'));
          setLoad(false);
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
};

export const deletePropertyStageProcess = async (
  propertyStage: string,
  organization_id: string,
  propertyStageList: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
      propertyStageList.splice(index, 1);
        try {
          firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                PropertyStages: propertyStageList,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Property Stage Deleted', 'success'));
          setLoad(false);
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
};
export const deletePropertyTypeProcess = async (
  propertyType: string,
  organization_id: string,
  propertyTypeList: any[],
  propertySubTypeList: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {    
        try {
          const exists = propertySubTypeList.some(item => item.property_type === propertyType);
          if(exists){
            dispatcher(showSnackbarAction('Please Delete Property Sub Type', 'error'));
            setLoad(false);
          }else{
            propertyTypeList.splice(index, 1);
            firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                PropertyTypes: propertyTypeList,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Property Type Deleted', 'success'));
          setLoad(false);
          }
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
};
export const deletePropertySubTypeProcess = async (
  propertyType: string,
  organization_id: string,
  propertyTypeList: any[],
  propertySubTypeList: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {    
        try {
          propertySubTypeList.splice(index, 1);
            firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                PropertySubTypes: propertySubTypeList,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Property Sub Type Deleted', 'success'));
          setLoad(false);
          
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
};

export const deleteResidential = async (
  residential: string,
  organization_id: string,
  resList: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  let data: any[] = [];
  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('property_sub_type', '==', residential)
    .get()
    .then((contact) => {
      if (contact) {
        contact.forEach((item) => {
          data.push(item.data());
        });
      }

      if (data.length !== 0) {
        dispatcher(
          showSnackbarAction(
            'This Residential Type Is Used In Some Contacts',
            'error'
          )
        );
        setLoad(false);
      } else {
        resList.splice(index, 1);
        try {
          firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                resTypes: resList,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Residential Type Deleted', 'success'));
          setLoad(false);
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
      }
    });
};

export const deleteBudget = (
  budget: string,
  organization_id: string,
  budgetList: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  let data: any[] = [];
  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('budget', '==', budget)
    .get()
    .then((contact) => {
      if (contact) {
        contact.forEach((item) => {
          data.push(item.data());
        });
      }

      if (data.length !== 0) {
        dispatcher(
          showSnackbarAction('This Budget Is Used In Some Contacts', 'error')
        );
        setLoad(false);
      } else {
        budgetList.splice(index, 1);
        try {
          firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                budgets: budgetList,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Budget Deleted', 'success'));
          setLoad(false);
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
      }
    });
};

export const deleteLeadSource = (
  leadSource: string,
  organization_id: string,
  leadSourceList: any[],
  index: number,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  let data: any[] = [];
  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('lead_source', '==', leadSource)
    .get()
    .then((contact) => {
      if (contact) {
        contact.forEach((item) => {
          data.push(item.data());
        });
      }

      if (data.length !== 0) {
        dispatcher(
          showSnackbarAction(
            'This Lead Source Is Used In Some Contacts',
            'error'
          )
        );
        setLoad(false);
      } else {
        leadSourceList.splice(index, 1);
        try {
          firestore
            .collection('organizationResources')
            .doc(organization_id)
            .set(
              {
                leadSources: leadSourceList,
              },
              { merge: true }
            );
          dispatcher(showSnackbarAction('Lead Source Deleted', 'success'));
          setLoad(false);
        } catch (e) {
          console.log('Error:', e);
          dispatcher(showSnackbarAction('Error! Try Again.', 'error'));
          setLoad(false);
        }
      }
    });
};

export const editLocation = (
  organization_id: string,
  oldLocation: string,
  newLocation: string,
  locationList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  locationList.forEach((location: any) => {
    if (location.location_name === oldLocation) {
      location.location_name = newLocation;
    }
  });

  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('location', '==', oldLocation)
    .get()
    .then((contact) => {
      const batch = firestore.batch();
      if (contact.docs.length <= 500) {
        contact.docs.forEach((item) => {
          const contactsRef = firestore.collection('contacts').doc(item.id);
          batch.set(contactsRef, { location: newLocation }, { merge: true });
        });

        const resRef = firestore
          .collection('organizationResources')
          .doc(organization_id);
        batch.set(
          resRef,
          {
            locations: locationList,
          },
          { merge: true }
        );
        batch.commit().then(() => {
          dispatcher(showSnackbarAction('Location Updated', 'success'));
          setLoad(false);
        });
      }
    });
};

export const editProjectDetails = (
  organization_id: string,
  projectsList: any[],
  oldProjectName: string,
  project_name: string,
  developer_name: string,
  address: string,
  property_type: string,
  project_status: string,
  property_stage: string,
  rera_link: string,
  walkthrough_link: string,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  projectsList.forEach((project) => {
    if (project.project_name === oldProjectName) {
      project.project_name = project_name;
      project.developer_name = developer_name;
      project.address = address;
      project.property_type = property_type;
      project.project_status = project_status;
      project.rera_link = rera_link;
      project.walkthrough_link = walkthrough_link;
      project.property_stage = property_stage;
    }
  });
  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('project', '==', oldProjectName)
    .get()
    .then((contact) => {
      const batch = firestore.batch();
      if (contact.docs.length <= 500) {
        contact.docs.forEach((item) => {
          const contactsRef = firestore.collection('contacts').doc(item.id);
          batch.set(contactsRef, { project: project_name }, { merge: true });
        });
      } else {
        contact.docs.forEach((contacts) => {
          firestore.collection('contacts').doc(contacts.id).set(
            {
              project: project_name,
            },
            { merge: true }
          );
        });
      }
      const resRef = firestore
        .collection('organizationResources')
        .doc(organization_id);
      batch.set(
        resRef,
        {
          projects: projectsList,
        },
        { merge: true }
      );
      batch
        .commit()
        .then(() => {
          dispatcher(showSnackbarAction('Project Updated', 'success'));
          setLoad(false);
        })
        .catch((error) => {
          console.log('edit project error:', error);
        });
    });
};

export const editBudget = (
  organization_id: string,
  oldBudget: string,
  newBudget: string,
  budgetList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  budgetList.forEach((budget: any) => {
    if (budget.budget === oldBudget) {
      budget.budget = newBudget;
    }
  });

  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('budget', '==', oldBudget)
    .get()
    .then((contact) => {
      const batch = firestore.batch();
      if (contact.docs.length <= 500) {
        contact.docs.forEach((item) => {
          const contactsRef = firestore.collection('contacts').doc(item.id);
          batch.set(contactsRef, { budget: newBudget }, { merge: true });
        });

        const resRef = firestore
          .collection('organizationResources')
          .doc(organization_id);
        batch.set(
          resRef,
          {
            budgets: budgetList,
          },
          { merge: true }
        );
        batch.commit().then(() => {
          dispatcher(showSnackbarAction('Budget Updated', 'success'));
          setLoad(false);
        });
      }
    });
};

export const editTransferReason = (
  organization_id: string,
  oldTransferReason: string,
  newTransferReason: string,
  transferReasonList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  const modifiedArray = transferReasonList.map((item: string) => item === oldTransferReason ? newTransferReason : item);

  const batch = firestore.batch();
  const resRef = firestore
          .collection('organizationResources')
          .doc(organization_id);
        batch.set(
          resRef,
          {
            TransferReasons: modifiedArray,
          },
          { merge: true }
        );
        batch.commit().then(() => {
          dispatcher(showSnackbarAction('Transfer Reason Updated', 'success'));
          setLoad(false);
        });
};

export const editPropertyStage = (
  organization_id: string,
  oldTransferReason: string,
  newTransferReason: string,
  propertyStageList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  const modifiedArray = propertyStageList.map((item: string) => item === oldTransferReason ? newTransferReason : item);

  const batch = firestore.batch();
  const resRef = firestore
          .collection('organizationResources')
          .doc(organization_id);
        batch.set(
          resRef,
          {
            PropertyStages: modifiedArray,
          },
          { merge: true }
        );
        batch.commit().then(() => {
          dispatcher(showSnackbarAction('Property Stage Updated', 'success'));
          setLoad(false);
        });
};

export const editPropertyType = (
  organization_id: string,
  oldPropertyType: string,
  newPropertyType: string,
  propertyTypeList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  const modifiedArray = propertyTypeList.map((item: string) => item === oldPropertyType ? newPropertyType : item);

  const batch = firestore.batch();
  const resRef = firestore
          .collection('organizationResources')
          .doc(organization_id);
        batch.set(
          resRef,
          {
            PropertyTypes: modifiedArray,
          },
          { merge: true }
        );
        batch.commit().then(() => {
          dispatcher(showSnackbarAction('Property Type Updated', 'success'));
          setLoad(false);
        });
};

export const editPropertySubType = (
  organization_id: string,
  oldSubType: string,
  newSubType: string,
  newPropertySubType: string,
  propertySubTypeList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  if(newPropertySubType==="Select"){
    dispatcher(showSnackbarAction('Please Choose Property Type', 'error'));
    setLoad(false);
  }else{
    propertySubTypeList.forEach((propSubType: any) => {
      if (propSubType.property_sub_type === oldSubType) {
        propSubType.property_sub_type = newSubType;
        propSubType.property_type = newPropertySubType;
      }
    });
    const batch = firestore.batch();
          const resRef = firestore
            .collection('organizationResources')
            .doc(organization_id);
          batch.set(
            resRef,
            {
              PropertySubTypes: propertySubTypeList,
            },
            { merge: true }
          );
          batch.commit().then(() => {
            dispatcher(showSnackbarAction('Property sub Type Updated', 'success'));
            setLoad(false);
          });

  }
};

export const editLeadSource = (
  organization_id: string,
  oldLeadSource: string,
  newLeadSource: string,
  newLeadSourceColor: string,
  leadSourceList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  leadSourceList.forEach((leadSource: any) => {
    if (leadSource.leadSource === oldLeadSource) {
      leadSource.leadSource = newLeadSource;
      leadSource.leadSourceColor = newLeadSourceColor;
    }
  });

  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('lead_source', '==', oldLeadSource)
    .get()
    .then((contact) => {
      const batch = firestore.batch();
      if (contact.docs.length <= 500) {
        contact.docs.forEach((item) => {
          const contactsRef = firestore.collection('contacts').doc(item.id);
          batch.set(
            contactsRef,
            { lead_source: newLeadSource },
            { merge: true }
          );
        });

        const resRef = firestore
          .collection('organizationResources')
          .doc(organization_id);
        batch.set(
          resRef,
          {
            leadSources: leadSourceList,
          },
          { merge: true }
        );
        batch.commit().then(() => {
          dispatcher(showSnackbarAction('Lead Source Updated', 'success'));
          setLoad(false);
        });
      }
    });
};

export const editCommType = (
  organization_id: string,
  oldValue: string,
  newValue: string,
  commList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  commList.forEach((item: any, index: number) => {
    if (item === oldValue) {
      commList[index] = newValue;
    }
  });

  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('property_sub_type', '==', oldValue)
    .get()
    .then((contact) => {
      const batch = firestore.batch();
      if (contact.docs.length <= 500) {
        contact.docs.forEach((item) => {
          const contactsRef = firestore.collection('contacts').doc(item.id);
          batch.set(
            contactsRef,
            { property_sub_type: newValue },
            { merge: true }
          );
        });

        const resRef = firestore
          .collection('organizationResources')
          .doc(organization_id);
        batch.set(
          resRef,
          {
            comTypes: commList,
          },
          { merge: true }
        );
        batch.commit().then(() => {
          dispatcher(
            showSnackbarAction('Commercial Property Type Updated', 'success')
          );
          setLoad(false);
        });
      }
    });
};

export const editResType = (
  organization_id: string,
  oldValue: string,
  newValue: string,
  resList: any,
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  resList.forEach((item: any, index: number) => {
    if (item === oldValue) {
      resList[index] = newValue;
    }
  });

  firestore
    .collection('contacts')
    .where('organization_id', '==', organization_id)
    .where('property_sub_type', '==', oldValue)
    .get()
    .then((contact) => {
      const batch = firestore.batch();
      if (contact.docs.length <= 500) {
        contact.docs.forEach((item) => {
          const contactsRef = firestore.collection('contacts').doc(item.id);
          batch.set(
            contactsRef,
            { property_sub_type: newValue },
            { merge: true }
          );
        });

        const resRef = firestore
          .collection('organizationResources')
          .doc(organization_id);
        batch.set(
          resRef,
          {
            resTypes: resList,
          },
          { merge: true }
        );
        batch.commit().then(() => {
          dispatcher(
            showSnackbarAction('Residential Property Type Updated', 'success')
          );
          setLoad(false);
        });
      }
    });
};

export const uploadProjectResources = async (
  file: any,
  projectData: any,
  type: string,
  fileName: string,
  dispatcher: any
) => {
  const path = `project/${projectData.project_id}/${type}/${fileName}`;
  const reference = storage.ref(path);

  try {
    await reference.put(file);
    const url = await reference.getDownloadURL();
    let fileNameSplit = fileName.split('.');
    let fileType = '';

    if (fileNameSplit[fileNameSplit.length - 1] === 'pdf') {
      fileType = 'pdf';
    } else {
      fileType = 'image';
    }

    try {
      await firestore
        .collection('projectResources')
        .doc(projectData.project_id)
        .set(
          {
            [type]: Firebase.firestore.FieldValue.arrayUnion({
              link: url,
              created_at: Firebase.firestore.Timestamp.now(),
              name: fileName,
              type: fileType,
            }),
          },
          { merge: true }
        );
      dispatcher(showSnackbarAction('Document Added!!', 'success'));
    } catch (error) {
      console.log('project upload Error', error);
      dispatcher(showSnackbarAction('Error!! Try Again!!', 'error'));
    }
  } catch (error) {
    console.log('project upload Error', error);
    dispatcher(showSnackbarAction('Error!! Try Again!!', 'error'));
  }
};

export const fetchProjectDocuments = (
  project_id: string,
  setBrochures: (data: any[]) => void,
  setPriceLists: (data: any[]) => void,
  setLayouts: (data: any[]) => void,
  setForms: (data: any[]) => void,
  setAttachmentsList: (data: any[]) => void,
  setImages: (data: any[]) => void
) => {
  firestore
    .collection('projectResources')
    .doc(project_id)
    .onSnapshot((resources) => {
      if (resources) {
        const resourcesData = resources.data();
        if (resourcesData?.brochures) {
          setBrochures(resourcesData.brochures);
        } else {
          setBrochures([]);
        }
        if (resourcesData?.priceLists) {
          setPriceLists(resourcesData.priceLists);
        } else {
          setPriceLists([]);
        }
        if (resourcesData?.video) {
          setAttachmentsList(resourcesData.video);
        } else {
          setAttachmentsList([]);
        }
        if (resourcesData?.layouts) {
          setLayouts(resourcesData.layouts);
        } else {
          setLayouts([]);
        }
        if (resourcesData?.forms) {
          setForms(resourcesData.forms);
        } else {
          setForms([]);
        }
        if (resourcesData?.images) {
          setImages(resourcesData.images);
        } else {
          setImages([]);
        }
      } else {
        console.log('document not found');
      }
    });
};

export const setProjectImage = async (
  organization_id: string,
  file: any,
  projectList: any[],
  projectData: any,
  deletePrev: boolean,
  dispatcher: any
) => {
  const path = `project/${projectData.project_id}/thumbnail`;
  const reference = storage.ref(path);
  if (deletePrev) {
    await reference.delete();
  }
  try {
    await reference.put(file);
    const url = await reference.getDownloadURL();
    try {
      let projects = [...projectList];
      projects.forEach((project: any, index: number) => {
        if (project.project_id === projectData.project_id) {
          let newProject = { ...project, project_image: url };
          projects[index] = newProject;
        }
      });
      await firestore
        .collection('organizationResources')
        .doc(organization_id)
        .set({ projects }, { merge: true });
    } catch (error) {
      console.log('Project Image Upload Error', error);
      dispatcher(showSnackbarAction('Error!!', 'error'));
    }
  } catch (error) {
    console.log('Project Image Upload Error', error);
    dispatcher(showSnackbarAction('Error!!', 'error'));
  }
};

export const createFAQAdmin = (
  question: string,
  answer: string,
  serialNumber: string,
  videoUrl:string,
  dispatcher: any,

  setLoad: (data: boolean) => void,
  history: any
) => {
  let numberType = Math.floor((Math.random() * 100000) + 1);
  let q = question.substring(0, 2)
  let a = answer.substring(0, 2)
  let id = `${q}${numberType}${a}`
  firestore
    .collection('faq')
    .doc(" ")
    .set(
      {
        FAQ: Firebase.firestore.FieldValue.arrayUnion({
          question: question,
          answer: answer,
          serialNumber: serialNumber,
          videoUrl:videoUrl,
          profile:"Super Admin",
          id
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('FAQ Added', 'success'));
      setLoad(false);
      history.push('/faq');
    })
    .catch((error) => {
      console.log('FAQ Error', error);
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
      setLoad(false);
      history.push('/faq');
    });
};

export const createFAQ = (
  organization_id: string,
  question: string,
  answer: string,
  serialNumber: string,
  videoUrl:string,
  dispatcher: any,

  setLoad: (data: boolean) => void,
  history: any
) => {
  let numberType = Math.floor((Math.random() * 100000) + 1);
  let q = question.substring(0, 2)
  let a = answer.substring(0, 2)
  let id = `${q}${numberType}${a}`
  firestore
    .collection('faq')
    .doc(organization_id)
    .set(
      {
        FAQ: Firebase.firestore.FieldValue.arrayUnion({
          question: question,
          answer: answer,
          serialNumber: serialNumber,
          videoUrl:videoUrl,
          id
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('FAQ Added', 'success'));
      setLoad(false);
      history.push('/faq');
    })
    .catch((error) => {
      console.log('FAQ Error', error);
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
      setLoad(false);
      history.push('/faq');
    });
};


export const createAutoFAQ =async (
  organization_id: any,
  faqArr: any,
) => {
  const faqRef = firestore.collection('faq').doc(organization_id);
  try {
    for (const faq of faqArr) {
      await faqRef.set(
        {
          FAQ: Firebase.firestore.FieldValue.arrayUnion(faq)
        },
        { merge: true }
      );
    }
    console.log("FAQ Add Successfully");
  } catch (error) {
    console.log('FAQ Error', error);
  }
};


export const createAutoNews =async (
  organization_id: any,
  newsArr: any,
) => {
  const newsRef = firestore.collection('news').doc(organization_id);
  try {
    for (const news of newsArr) {
      await newsRef.set(
        {
          news: Firebase.firestore.FieldValue.arrayUnion(news)
        },
        { merge: true }
      );
    }
    console.log("News Add Successfully");
  } catch (error) {
    console.log('News Error', error);
  }
};

export const createLicensesCost = (
  serialNumber: string,
  tpDays:string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  history: any
) => {
  firestore
    .collection('licensescost')
    .doc('QzA8Wu90bmJkLc6eYMHd') // Auto-generated document ID
    .set({
      licensesCost: serialNumber,no_employees:tpDays
    })
    .then(() => {
      dispatcher(showSnackbarAction('Licenses Cost Added', 'success'));
      setLoad(false);
      history.push('/');
    })
    .catch((error) => {
      console.log('Licenses Cost Error', error);
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
      setLoad(false);
      history.push('/');
    });
};

export const updateFAQ = (
  organization_id: string,
  filterDataArr: any,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  history: any,
) => {
  firestore
    .collection('faq')
    .doc(organization_id).set(
      { FAQ: filterDataArr },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('FAQ Update', 'success'));
      setLoad(false);
      history.push('/faq');
    })
    .catch((error) => {
      console.log('FAQ Error', error);
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
      setLoad(false);
      history.push('/faq');
    });
};

export const updateFAQAdmin = (
  filterDataArr: any,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  history: any,
) => {
  firestore
    .collection('faq')
    .doc(" ").set(
      { FAQ: filterDataArr },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('FAQ Update', 'success'));
      setLoad(false);
      history.push('/faq');
    })
    .catch((error) => {
      console.log('FAQ Error', error);
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
      setLoad(false);
      history.push('/faq');
    });
};

export const deleteFAQAdmin = (
  filterDataArr: any,
  dispatcher: any,
  history: any,
) => {
  firestore
    .collection('faq')
    .doc(" ").set(
      { FAQ: filterDataArr },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('FAQ Deleted', 'success'));
      history.push('/faq');
    })
    .catch((error) => {
      console.log('FAQ Error ', error);
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
      history.push('/faq');
    });
};

export const deleteFAQ = (
  organization_id: string,
  filterDataArr: any,
  dispatcher: any,
  history: any,
) => {
  firestore
    .collection('faq')
    .doc(organization_id).set(
      { FAQ: filterDataArr },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('FAQ Deleted', 'success'));
      history.push('/faq');
    })
    .catch((error) => {
      console.log('FAQ Error ', error);
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
      history.push('/faq');
    });
};

export const fetchFAQAdmin = (
  setFAQ: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection('faq')
    .doc(" ")
    .onSnapshot((faqs) => {
      if (faqs) {
        const faqData = faqs.data();
        if (faqData?.FAQ) {
          const filterData = faqData?.FAQ.sort((a: any, b: any) => a.serialNumber - b.serialNumber)
          setFAQ(filterData);
        }
        else {
          setFAQ([]);
        }
      } else {
        console.log('FAQ Org not found');
      }
    });
  return subscriber;
};

export const fetchFAQ = (
  organization_id: string,
  setFAQ: (data: any[]) => void
) => {
  const getFAQWithOrgId = firestore
    .collection('faq')
    .doc(organization_id)
    .onSnapshot(doc => {
      if (doc.exists) {
        updateFAQs();
      }
    }, error => {
      console.error(`Error fetching document with ID ${organization_id}: `, error);
    });

  const getFAQWithOutOrgId = firestore
    .collection('faq')
    .doc(' ')
    .onSnapshot(doc => {
      if (doc.exists) {
        updateFAQs();
      }
    }, error => {
      console.error(`Error fetching document with ID ' ': `, error);
    });

  const updateFAQs = async () => {
    try {
      const [specificDoc, generalDoc] = await Promise.all([
        firestore.collection('faq').doc(organization_id).get(),
        firestore.collection('faq').doc(' ').get()
      ]);

      const specificData = specificDoc.exists ? specificDoc.data() : null;
      const generalData = generalDoc.exists ? generalDoc.data() : null;

      const allFAQs = [
        ...(specificData?.FAQ ?? []),
        ...(generalData?.FAQ ?? [])
      ];

      const sortedFAQs = allFAQs.sort((a: any, b: any) => a.serialNumber - b.serialNumber);
      setFAQ(sortedFAQs);
    } catch (error) {
      console.error('Error updating FAQs: ', error);
      setFAQ([]);
    }
  };
  // Cleanup function to unsubscribe from the listeners
  return () => {
    getFAQWithOrgId();
    getFAQWithOutOrgId();
  };
};
export const fetchLicensesCost = (
  setFAQ: (data: any[]) => void,
  setLoad?: (data: boolean) => void
  ) => {
    setLoad && setLoad(true);
  const subscriber = firestore
    .collection('licensescost')
    .get()
    .then((querySnapshot) => {
      const licensesCostData = querySnapshot.docs.map((doc) => doc.data());
      // Do something with the licensesCostData array
      setFAQ(licensesCostData);
      setLoad && setLoad(false);
    })
    .catch((error) => {
      console.log('Fetch Licenses Cost Error', error);
      setLoad && setLoad(false);
    });
  return subscriber;
};
const sortResources = (a: any, b: any) => {
  if (a.created_at.toDate() > b.created_at.toDate()) {
    return -1;
  } else {
    return 1;
  }
};
export const mapCallLogs = (
  data: { [key: string]: any }[],
  map: { [key: string]: string },
  organization_id: string,
  dispatcher: any,
  submit: () => void,
  usersList: any[],
  setLoad: (data: boolean) => void
) => {
  let templateList: any[] = [];
  data.forEach((call, index) => {
    let uid = '';
    if (map['Owner']) {
      const userData = usersList.filter(
        (item) =>
          String(item.user_email).toLowerCase() ===
          String(call[map['Owner']].toLowerCase())
      );
      uid = userData.length === 0 ? '' : userData[0].uid;
      if (uid === '') {
        dispatcher(
          showSnackbarAction(
            'Please Provide The Owner For the Contact At: ' + index + 1,
            'error'
          )
        );
        return;
      }
    }
    if (!map.hasOwnProperty('Duration')) {
      dispatcher(showSnackbarAction('Please Select Call Duration', 'error'));
      templateList = [];
      setLoad(false);
      return;
    } else if (!map.hasOwnProperty('Contact ID')) {
      dispatcher(showSnackbarAction('Please Select Contact ID', 'error'));
      templateList = [];
      setLoad(false);
      return;
    } else if (call[map['Duration']] === '') {
      dispatcher(
        showSnackbarAction('Invalid Duration at row ' + call.sno, 'error')
      );
      templateList = [];
      setLoad(false);
      return;
    } else if (call[map['Contact ID']] === '') {
      dispatcher(
        showSnackbarAction('Invalid Contact ID at row ' + call.sno, 'error')
      );
      templateList = [];
      setLoad(false);
      return;
    }
    templateList.push({
      callTime: map['Duration'] ? call[map['Duration']] : '',
      created_at: map['Created At']
        ? call[map['Created At']] !== ''
          ? Firebase.firestore.Timestamp.fromDate(
            moment(call[map['Created At']], 'MM/DD/YY HH:mm').toDate()
          )
          : Firebase.firestore.Timestamp.now()
        : Firebase.firestore.Timestamp.now(),
      contactId: map['Contact ID'] ? call[map['Contact ID']] : '',
      uid: uid,
    });
  });

  if (templateList.length === data.length) {
    createCallLogs(
      templateList,
      dispatcher,
      submit,
      (data: boolean) => setLoad(data),
      organization_id
    );
  } else {
    setLoad(false);
  }
};

const createCallLogs = async (
  data: any[],
  dispatcher: any,
  submit: () => void,
  setLoad: (data: boolean) => void,
  organization_id: any
) => {
  let callLogs: { [key: string]: [{}] } = {};
  let uidMap: { [key: string]: string } = {};
  data.forEach((item) => {
    if (callLogs[item.contactId]) {
      callLogs[item.contactId].push({
        callTime: item.callTime,
        created_at: item.created_at,
      });
    } else {
      callLogs[item.contactId] = [
        { callTime: item.callTime, created_at: item.created_at },
      ];
    }
    if (uidMap[item.contactId]) {
      uidMap[item.contactId] = item.uid;
    } else {
      uidMap[item.contactId] = item.uid;
    }
  });
  const callLogsData: any[] = [];
  Object.keys(callLogs).forEach((key) => {
    callLogsData.push({ id: key, data: callLogs[key], uid: uidMap[key] });
  });
  if (callLogsData.length > 500) {
    dispatcher(setTotal(callLogsData.length));
    const splitedData = chunk(callLogsData, 500);
    dispatcher(setCompleted(0));
    let total = 0;
    try {
      for (let i = 0; i < splitedData.length; i++) {
        total += splitedData[i].length;
        await createMultipleCallLogs(splitedData[i], organization_id);
        dispatcher(setCompleted(total));
      }
      dispatcher(setTotal(0));

      dispatcher(showSnackbarAction('Call Logs Uploaded!!', 'success'));
      submit();
      setLoad(false);
    } catch (error) {
      console.log('Call Logs upload error!!', error);
      setLoad(false);
      dispatcher(showSnackbarAction('Try Again!!', 'error'));
    }
  } else {
    createMultipleCallLogs(callLogsData, organization_id)
      .then(() => {
        dispatcher(showSnackbarAction('Call Logs Uploaded', 'success'));
        setLoad(false);
        submit();
      })
      .catch((error) => {
        console.log('Call logs upload error!!', error);
        setLoad(false);
      });
  }
};

const createMultipleCallLogs = async (data: any[], organization_id: string) => {
  const batch = firestore.batch();

  data.forEach((key) => {
    const callRef = firestore.collection('contactResources').doc(key.id);
    batch.set(
      callRef,
      {
        callLogs: key.data.sort(sortResources),
        organization_id: organization_id,
        uid: key.uid,
      },
      { merge: true }
    );
  });

  batch.commit();
};

export const mapNotes = (
  data: { [key: string]: any }[],
  map: { [key: string]: string },
  dispatcher: any,
  submit: () => void,
  setLoad: (data: boolean) => void
) => {
  let templateList: any[] = [];

  data.forEach((note, index) => {
    if (!map.hasOwnProperty('Note')) {
      dispatcher(showSnackbarAction('Please Select Note', 'error'));
      templateList = [];
      setLoad(false);
      return;
    } else if (!map.hasOwnProperty('Contact ID')) {
      dispatcher(showSnackbarAction('Please Select Contact ID', 'error'));
      templateList = [];
      setLoad(false);
      return;
    } else if (note[map['Note']] === '') {
      dispatcher(
        showSnackbarAction('Invalid Note at row ' + note.sno, 'error')
      );
      templateList = [];
      setLoad(false);
      return;
    } else if (note[map['Contact ID']] === '') {
      dispatcher(
        showSnackbarAction('Invalid Contact ID at row ' + note.sno, 'error')
      );
      templateList = [];
      setLoad(false);
      return;
    }
    templateList.push({
      note: map['Note'] ? note[map['Note']] : '',
      created_at: map['Created At']
        ? note[map['Created At']] !== ''
          ? Firebase.firestore.Timestamp.fromDate(
            moment(note[map['Created At']], 'MM/DD/YY HH:mm').toDate()
          )
          : Firebase.firestore.Timestamp.now()
        : Firebase.firestore.Timestamp.now(),
      contactId: map['Contact ID'] ? note[map['Contact ID']] : '',
    });
  });

  if (templateList.length === data.length) {
    createNotes(templateList, dispatcher, submit, (data: boolean) =>
      setLoad(data)
    );
  } else {
    setLoad(false);
  }
};

const createNotes = async (
  data: any[],
  dispatcher: any,
  submit: () => void,
  setLoad: (data: boolean) => void
) => {
  let notes: { [key: string]: [{}] } = {};

  data.forEach((item) => {
    if (notes[item.contactId]) {
      notes[item.contactId].push({
        note: item.note,
        created_at: item.created_at,
      });
    } else {
      notes[item.contactId] = [
        { note: item.note, created_at: item.created_at },
      ];
    }
  });
  const notesData: any[] = [];
  Object.keys(notes).forEach((key) => {
    notesData.push({ id: key, data: notes[key] });
  });
  if (notesData.length > 500) {
    dispatcher(setTotal(notesData.length));
    const splitedData = chunk(notesData, 500);
    dispatcher(setCompleted(0));
    let total = 0;
    try {
      for (let i = 0; i < splitedData.length; i++) {
        total += splitedData[i].length;
        await createMultipleNotes(splitedData[i]);
        dispatcher(setCompleted(total));
      }
      dispatcher(setTotal(0));
      dispatcher(showSnackbarAction('Notes Uploaded!!', 'success'));
      submit();
      setLoad(false);
    } catch (error) {
      console.log('Notes upload error!!', error);
      setLoad(false);
      dispatcher(showSnackbarAction('Try Again!!', 'error'));
    }
  } else {
    createMultipleNotes(notesData)
      .then(() => {
        dispatcher(showSnackbarAction('Notes Uploaded', 'success'));
        setLoad(false);
        submit();
      })
      .catch((error) => {
        console.log('Notes upload error!!', error);
        setLoad(false);
      });
  }
};

const createMultipleNotes = async (data: any[]) => {
  const batch = firestore.batch();

  data.forEach((item) => {
    const noteRef = firestore.collection('contactResources').doc(item.id);
    batch.set(
      noteRef,
      {
        notes: item.data.sort(sortResources),
      },
      { merge: true }
    );
  });
  batch.commit();
};

export const mapTasks = (
  data: { [key: string]: any }[],
  map: { [key: string]: string },
  dispatcher: any,
  submit: () => void,
  setLoad: (data: boolean) => void,
  organization_id: string,
  usersList: any[],
  name: string
) => {
  let templateList: any[] = [];
  let checkStatus = true;
  data.forEach((task: any, index: number) => {
    if (checkStatus === false) {
      setLoad(false);
      return;
    }
    let uid = '';
    if (!map.hasOwnProperty('Owner')) {
      dispatcher(showSnackbarAction('Please Select Owner', 'error'));

      checkStatus = false;
      return;
    }
    if (map['Owner']) {
      const userData = usersList.filter(
        (item) =>
          String(item.user_email).toLowerCase() ===
          String(task[map['Owner']].toLowerCase())
      );
      uid = userData.length === 0 ? '' : userData[0].uid;
      if (uid === '') {
        dispatcher(
          showSnackbarAction(
            'Please Provide The Owner For the Contact At: ' + index + 1,
            'error'
          )
        );
        checkStatus = false;
        return;
      }
    }

    if (!map.hasOwnProperty('Status')) {
      dispatcher(showSnackbarAction('Please Select Status', 'error'));
      checkStatus = false;
      return;
    } else if (
      task[map['Status']] === '' ||
      (properFormat(task[map['Status']]).trim() !== 'Overdue' &&
        properFormat(task[map['Status']]).trim() !== 'Pending' &&
        properFormat(task[map['Status']]).trim() !== 'Completed')
    ) {
      dispatcher(
        showSnackbarAction('Invalid Status at row ' + task.sno, 'error')
      );
      checkStatus = false;
      return;
    }

    if (properFormat(task[map['Status']]).trim() === 'Overdue') {
      checkStatus = OverdueTaskValidation(map, dispatcher, task);
    } else if (properFormat(task[map['Status']]).trim() === 'Completed') {
      checkStatus = CompletedTaskValidation(map, dispatcher, task);
    } else if (properFormat(task[map['Status']]).trim() === 'Pending') {
      checkStatus = PendingTaskValidation(map, dispatcher, task);
    }
    if (checkStatus === false) {
      return;
    }
    if (properFormat(task[map['Task Type']]) === 'Call Back') {
      if (task[map['Call Back Reason']] === '') {
        dispatcher(
          showSnackbarAction(
            'Invalid Call Back Reason at row: ' + task.sno,
            'error'
          )
        );
        checkStatus = false;
        return;
      }
    }
    let taskObj = {
      call_back_reason: map['Call Back Reason']
        ? task[map['Call Back Reason']]
        : '',
      created_at: map['Created At']
        ? task[map['Created At']] !== ''
          ? Firebase.firestore.Timestamp.fromDate(
            moment(task[map['Created At']], 'MM/DD/YY HH:mm').toDate()
          )
          : Firebase.firestore.Timestamp.now()
        : Firebase.firestore.Timestamp.now(),
      created_by: map['Created By'] ? task[map['Created By']] : name,
      customer_name: map['Customer Name'] ? task[map['Customer Name']] : '',
      due_date: map['Due Date']
        ? task[map['Due Date']] !== ''
          ? Firebase.firestore.Timestamp.fromDate(
            moment(task[map['Due Date']], 'MM/DD/YY HH:mm').toDate()
          )
          : ''
        : '',
      leadId: map['Lead Id'] ? task[map['Lead Id']] : '',
      status: map['Status']
        ? task[map['Status']] === 'Overdue' || task[map['Status']] === 'overdue'
          ? 'Pending'
          : properFormat(task[map['Status']])
        : '',
      type: map['Task Type'] ? properFormat(task[map['Task Type']]) : '',
      uid: uid,
    };
    if (task[map['Status']] === 'Completed') {
      let newTaskObj = {
        ...taskObj,
        completed_at: map['Completed Date']
          ? task[map['Completed Date']] !== ''
            ? Firebase.firestore.Timestamp.fromDate(
              moment(task[map['Completed Date']], 'MM/DD/YY HH:mm').toDate()
            )
            : ''
          : '',
      };
      templateList.push(newTaskObj);
    } else {
      templateList.push(taskObj);
    }
  });

  if (templateList.length === data.length) {
    createMultipleTasks(
      templateList,
      dispatcher,
      submit,
      (data: boolean) => setLoad(data),
      organization_id
    );
  } else {
    setLoad(false);
  }
};

export const createMultipleTasks = async (
  data: any[],
  dispatcher: any,
  submit: () => void,
  setLoad: (data: boolean) => void,
  organization_id: any
) => {
  let tasks: { [key: string]: [any] } = {};
  let uidMap: { [key: string]: string } = {};
  let check = true;
  data.forEach((item) => {
    if (check === false) {
      setLoad(false);
      submit();
      tasks = {};
      return;
    }

    let taskObj = {
      call_back_reason: item.call_back_reason,
      created_at: Firebase.firestore.Timestamp.now(),
      created_by: item.created_by,
      customer_name: item.customer_name,
      due_date: item.due_date,
      leadId: item.leadId,
      status: item.status,
      type: item.type,
    };

    if (tasks[item.leadId]) {
      if (item.status === 'Completed') {
        tasks[item.leadId].push({
          ...taskObj,
          completed_at: item.completed_at,
        });
      } else {
        tasks[item.leadId].push(taskObj);
      }
    } else {
      if (item.status === 'Completed') {
        let newTaskObj = { ...taskObj, completed_at: item.completed_at };
        tasks[item.leadId] = [newTaskObj];
      } else {
        tasks[item.leadId] = [taskObj];
      }
    }
    if (uidMap[item.leadId]) {
      uidMap[item.leadId] = item.uid;
    } else {
      uidMap[item.leadId] = item.uid;
    }
  });

  Object.keys(tasks).forEach((key) => {
    let pendingCount = 0;
    let pendingTask = {};
    tasks[key].forEach((task: any, index) => {
      if (task.status === 'Pending') {
        pendingCount += 1;
        pendingTask = { ...task };
        tasks[key].splice(index, 1);
      }
    });
    if (pendingCount > 1) {
      dispatcher(showSnackbarAction('Invalid Tasks for User: ' + key, 'error'));
      check = false;
      return;
    } else {
      if (Object.keys(pendingTask).length !== 0) {
        tasks[key].unshift(pendingTask);
      }
    }
  });
  const tasksData: any[] = [];
  Object.keys(tasks).forEach((key) => {
    tasksData.push({ id: key, data: tasks[key], uid: uidMap[key] });
  });
  if (tasksData.length > 500) {
    dispatcher(setTotal(tasksData.length));
    const splitedData = chunk(tasksData, 500);
    dispatcher(setCompleted(0));
    let total = 0;
    try {
      let check;
      for (let i = 0; i < splitedData.length; i++) {
        total += splitedData[i].length;
        check = await createTasks(
          splitedData[i],

          organization_id
        );
        dispatcher(setCompleted(total));
      }
      dispatcher(setTotal(0));
      if (check === true) {
        dispatcher(showSnackbarAction('Tasks Uploaded!!', 'success'));
        submit();
        setLoad(false);
      } else {
        submit();
        setLoad(false);
      }
    } catch (error) {
      console.log('Tasks upload error!!', error);
      setLoad(false);
      dispatcher(showSnackbarAction('Try Again!!', 'error'));
    }
  } else {
    let check = await createTasks(
      tasksData,

      organization_id
    );
    if (check === false) {
      dispatcher(showSnackbarAction('Error!!', 'error'));
      setLoad(false);
    } else {
      dispatcher(showSnackbarAction('Tasks Uploaded!!', 'success'));
      setLoad(false);
    }
  }
};

const createTasks = async (
  data: any[],

  organization_id: any
) => {
  const batch = firestore.batch();

  data.forEach((key) => {
    let status = key.data[0].status === 'Pending' ? 'ACTIVE' : 'INACTIVE';

    const taskRef = firestore.collection('tasks').doc(key.id);
    batch.set(
      taskRef,
      {
        tasks: key.data.sort(sortResources),
        organization_id: organization_id,
        uid: key.uid,
        status: status,
      },
      { merge: true }
    );
  });

  await batch.commit();
  return true;
};

export const createCommercialType = (
  organization_id: string,
  com_type: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        comTypes: Firebase.firestore.FieldValue.arrayUnion(com_type),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Commercial Type Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Commercial Type Error', error);
      setLoad(false);
    });
};

export const createResidentialType = (
  organization_id: string,
  res_type: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        resTypes: Firebase.firestore.FieldValue.arrayUnion(res_type),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Residential Type Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Residential Type Error', error);
      setLoad(false);
    });
};

export const createTransferReason = (
  organization_id: string,
  reason: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        TransferReasons: Firebase.firestore.FieldValue.arrayUnion(reason),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Transfer Reason Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Transfer Reason Error', error);
      setLoad(false);
    });
};

export const createPropertyType = (
  organization_id: string,
  reason: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        PropertyTypes: Firebase.firestore.FieldValue.arrayUnion(reason),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Property Type Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Transfer Reason Error', error);
      setLoad(false);
    });
};

export const createPropertyStage = (
  organization_id: string,
  reason: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        PropertyStages: Firebase.firestore.FieldValue.arrayUnion(reason),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Property Type Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Transfer Reason Error', error);
      setLoad(false);
    });
};

export const createPropertySubType = (
  organization_id: string,
  propSubType: string,
  propType: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void
) => {
  const propSubTypeObj = {
    property_type:propType,
    property_sub_type:propSubType
  };
  firestore
    .collection('organizationResources')
    .doc(organization_id)
    .set(
      {
        PropertySubTypes: Firebase.firestore.FieldValue.arrayUnion(propSubTypeObj),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('Property Sub Type Added', 'success'));
      setLoad(false);
      history.push('./resources');
    })
    .catch((error) => {
      console.log('Transfer Reason Error', error);
      setLoad(false);
    });
};

export const fetchTransferReason = (
  organization_id: string,
  setTransferReasons: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection('organizationResources')
    .doc(organization_id)
    .onSnapshot((resources) => {
      if (resources) {
        const resourceData = resources.data();

        if (resourceData?.TransferReasons) {
          setTransferReasons &&
            setTransferReasons(resourceData?.TransferReasons);
        } else {
          setTransferReasons && setTransferReasons([]);
        }
      } else {
        console.log('Org not found');
      }
    });
  return subscriber;
};