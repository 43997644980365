import React, { FunctionComponent, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// import { FacebookProvider, LoginButton } from 'react-facebook';
import styles from "./Integration.module.css";
import { themeColors } from "../../Components/theme";
import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookLogin from "react-facebook-login";
import { FacebookProvider, LoginButton, Like } from 'react-facebook';
// import LinkedInPage from "../IntegrationLinkedin/IntegrationLinkedin";
import axios from "axios";
import { autoCreateApi, createApi, deleteKeyFromApiTokenFB, fetchApi, getFbsslsValueFromApiTokenFB, updateApiFBPageId, updateApiFBPageIdUnScr, updateApiTokenFB } from "../../Services/organizations";
import FormDialogFB from "./DialogFB";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  showSnackbarAction,
  facebookUesrPageData
} from "../../Redux/actions";
import { Link, useLocation } from 'react-router-dom';
import { Circles, Audio, BallTriangle } from 'react-loader-spinner';
import FaceBookCrm from "../../Assets/Images/skhpreview.png";
import FaceBookCrmDark from "../../Assets/Images/fbtocrm.png";
import Loading from "../../Components/Loading/Loading";

type props = {
  user: any;
  contacts: any;
  history: any;
  role: any;
  organizationUsers: any;
  teamLeadUsers: any;
  theme: any;
  facebookUesrPageList: any;
  organizationId: any
};
interface LocationState {
  data: any; // Adjust the type of `data` as per your actual shape.
}
const loginButtonStyles = {
  backgroundColor: '#272944',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
};
const Sulekha: FunctionComponent<props> = ({
  user,
  contacts,
  history,
  role,
  organizationUsers,
  teamLeadUsers,
  theme,
  facebookUesrPageList,
  organizationId
}) => {
  const dispatcher = useDispatch();
  const location = useLocation();
  const state = location.state as LocationState || {};
  const [open, setOpen] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  const [API, setAPI] = useState<any[] | undefined>(undefined);
  const [filterActivePages, setFilterActivePages] = useState<any[] | undefined>(undefined);
  const [filterDeactivePages, setFilterDeactivePages] = useState<any[] | undefined>(undefined);
  const [filterAPI, setFilterAPI] = useState<any[] | undefined>(undefined);
  const [accessUserToken, setAccessUserToken] = React.useState('');
  const [rightOpen, setRightOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [userData, setUserData] = useState<any>();
  const [userPages, setUserPages] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const [APIKey, setAPIKey] = useState("");

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (user.organization_id) {
      fetchApi(user.organization_id, (val) => setAPI(val));
    }
  }, [user.organization_id]);

  useEffect(() => {
    if (API !== undefined && API.length > 0) {
      const filteredData = API && API.filter(item => item.source === "Sulekha");
      if (filteredData && filteredData.length > 0) {
        setAPIKey(filteredData[0].api_key)
      }
      const filterValue = API.some(item => item.source === "Sulekha");
      const leadSourceFilter = state.data.some(
          (item:any) => item.leadSource?.toLowerCase() === "Sulekha".toLowerCase()
        );
      const leadSourceFilterData = state?.data?.filter(
          (item:any) => item.leadSource?.toLowerCase() === "Sulekha".toLowerCase()
        );
      if (!leadSourceFilter) {
        dispatcher(showSnackbarAction("Before configuring the lead source in 'Sulekha,' ensure it is added to the resources!!", "error"));
        return;
      }
      if (!filterValue) {
        autoCreateApi(
          organizationId,
          "Sulekha",
          leadSourceFilterData[0]?.leadSource_id,
          "+91",
        );
      }
    }
  }, [API]);
 

  const bodyData = {
    'customer_name': "",
    'contact_no': "",
    'email': "",
    'country_code': "",
    'project': "",
    'campaign': "Sulekha",
    'token': APIKey
  }

  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };

  const buttonStyle = {
    /* Add your custom styles here */
    backgroundColor: 'blue',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };
  console.log("filterActivePages", filterActivePages);
  console.log("facebookUesrPageList", facebookUesrPageList);
  return (
    <>
      <div
    className={styles.parent}
    style={{ ...containerStyles, marginTop: "4%", overflow: "hidden", maxHeight: "500px", overflowY: "scroll", marginBottom: "20px", }}
  >
    {load && <Loading />}
    
<div style={{ width: "80%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "20px 20px 80px 50px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
          <div style={{ fontSize: "1.8rem", fontWeight: "500", margin: "20px 0px 0px 0px" }}>
        Sulekha
      </div>
      <div style={{ width: "80%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", marginBottom: "50px" }}>
        {/* image block */}
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center",flexDirection: "column" }}>
          <img src={FaceBookCrm} alt="" width={120} />
          <h2>Sulekha Integration</h2>
        </div>
        <p style={{ fontSize: "1rem" }}>Receive new leads from Sulekha in your Leads Rubix account.</p>
        <p style={{ fontSize: "1.1rem", fontWeight: "600", marginTop: "10px" }}>Forward Instructions to your Account Manager</p>
        <p style={{ fontSize: "1rem" }}>Connect to Leads Rubix by submitting a request directly to your Sulekha account manager. Please forward them the following instructions:</p>
        <div style={{backgroundColor: theme ?"lightgray":"rgb(39, 41, 68)",width:"100%"}}>
              <div style={{margin: "10px 10px 10px 10px",textAlign:"center"}}>
            <p>Please send my Sulekha leads to Leads Rubix CRM. The steps to do so are as below:</p>
            <p>My unique Webhook URL to send leads to:</p>
            <p>https://us-central1-leads-rubix.cloudfunctions.net/api-createContacts</p>
            <p style={{margin:"0px"}}>Method : POST</p>
            <p>Parameters :</p>
            {Object.entries(bodyData).map(([key, value]) => (
              <p key={key} style={{ margin: "0px" }}>
                {key} : {value}
              </p>
            ))}
          </div>
        </div>
        <p style={{ fontSize: "1rem", marginTop: "20px" }}>Your integration should be activated by the Sulekha team within a week's time. Once it is activated, you will start receiving leads directly in your Leads Rubix account.</p>
      </div>
    </div>
  </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    theme: state.theme.isLightMode,
    facebookUesrPageList: state.faceBookUserPagesData.data,
    organizationId: state.organization.id,
  };
};
export default connect(mapStateToProps)(Sulekha);
