import React, { FunctionComponent, useState, useEffect } from "react";
import styles from "./HolidayConfigModel.module.css";
import Modal from "react-modal";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { createApi, createHoliday, editApi, editHoliday } from "../../../Services/organizations";
import { showSnackbarAction } from "../../../Redux/actions";
import Loading from "../../Loading/Loading";
import { themeColors } from "../../theme";
import TextInput from "../../TextInput/TextInput";
import { v4 as uuidv4 } from 'uuid';


type Props = {
  open: boolean;
  close: () => void;
  organizationId?: any;
  theme?: any;
  edit?: boolean;
  editData?: any;
  editClose?: () => void;
  holidayData?:any;
};

const HolidayConfigModel: FunctionComponent<Props> = ({
  open,
  close,
  organizationId,
  theme,
  edit,
  editData,
  editClose,
  holidayData
}) => {
  const [load, setLoad] = useState<any>(false);
  const [formValues, setFormValues] = useState({ holiday_name: "", date: "" });
  const dispatcher = useDispatch();

  useEffect(() => {
    if (edit && editData) {
      setFormValues({
        holiday_name: editData.holiday_name || "",
        date: editData.date || "",
      });
    }
  }, [edit, editData]);

  const handleChange = (field: string, value: any) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    const { holiday_name, date } = formValues;
    if (!holiday_name || !date) {
      dispatcher(showSnackbarAction("Please fill all fields", "error"));
      return;
    }
    setLoad(true);
    const HolidayId = uuidv4();
    try {
      createHoliday(
        organizationId,
        holiday_name,
        date,
        HolidayId,
        dispatcher,
        (val) => setLoad(val),
        close
      );
    } catch (error) {
      console.error("Date formatting error:", error);
      dispatcher(showSnackbarAction("Invalid date format", "error"));
      setLoad(false);
    }
  }

  const handleEdit = () => {
    setLoad(true);
    editHoliday(
      organizationId,
      formValues.holiday_name,
      formValues.date,
      editData?.holiday_id,
      (val) => setLoad(val),
      close,
      editClose,
      dispatcher
    );
  };

  const handleClear = () => {
    setFormValues({
      holiday_name: "",
      date: "",
    });
  };
  const modalStyle = {
    content: {
      backgroundColor: theme
        ? themeColors.backgroundColor
        : themeColors.backgroundColorDark,
      color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    },
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      className={styles.parent}
      overlayClassName={styles.overlay}
      style={modalStyle}
    >
      {load && <Loading />}
      <div className={styles.headingContainer}>
        <p
          className={styles.heading}
          style={{ color: theme ? "#272944" : "white" }}
        >
          {edit === true ? <>Edit Holiday</> : <>Create Holiday</>}
        </p>
      </div>
      <div className={styles.profileContainer} style={{ paddingRight: "43px" }}>
        <label style={{marginBottom:"10px"}} >Holiday Name</label>
        <input
          type="text"
          style={{padding:"8px",borderRadius:"5px",border:"1px solid",width:"100%"}}
          value={formValues.holiday_name}
          onChange={(e) => handleChange("holiday_name", e.target.value)}
        />
      </div>
      <div className={styles.profileContainer}>
        <label style={{ marginTop: "20px",marginBottom:"10px"}}> Date</label>
        <input
          type="date"
          style={{padding:"8px",borderRadius:"5px",border:"1px solid",width:"91%"}}
          value={formValues.date}
          onChange={(e) => handleChange("date", e.target.value)}
        />
        </div>
        <div style={{marginTop:"50px"}}>
          <button className={styles.saveButton} onClick={handleClear}>
            Clear
          </button>
          <button
            className={styles.saveButton}
            onClick={edit ? handleEdit : handleSave}
            // disabled={!formValues.holiday_name || !formValues.date}
          >
            Save
          </button>
        </div>
    </Modal>
  );
};
const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(HolidayConfigModel);
