import React, { FunctionComponent, useState, useRef } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import styles from "./LeadDistributorLogic.module.css";
import Lottie from "react-lottie";
import noData from "../../Assets/animations/no-data.json";
import { themeColors } from "../../Components/theme";
import Label from "../../Components/Label";
import Dropdown from "../../Components/DropDown/Dropdown";
import {
  contactResources,
  CreateLeadDistributionNormal,
  CreateLeadDistributionRoundrobin,
  editLeadDistributionNormal,
  fetchConstants,
  fetchLeadDistributions,
  fetchpropertySubType,
} from "../../Services/contacts";
import { showSnackbarAction } from "../../Redux/actions";
import { useLocation } from "react-router-dom";
import Firebase from "firebase/app";
import { v4 as uuidv4 } from 'uuid';

interface LocationState {
  data: any; // Type this according to your actual data structure
  index: number;
}

type props = {
  history?: any;
  user?: any;
  organizationUsers?: any;
  theme?: any;
  conatctHeader?: any;
};
const LeadDistributorLogic: FunctionComponent<props> = ({
  history,
  user,
  organizationUsers,
  theme,
  conatctHeader,
}) => {
  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.8);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const dispatcher = useDispatch();
  const [projectSelected, setProjectSelected] = useState<any>([]);

  const [locationSelected, setlocationSelected] = useState<any>([]);
  const [budgetSelected, setBudgetSelected] = useState<any>([]);
  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [sourceSelected, setSourceSelected] = useState<any>();
  const [sourceDuplicateSelected, setDuplicateSourceSelected] =
    useState<boolean>(false);
  const [associateSelected, setAssociateSelected] = useState<any>([]);
  const [leadManagerSelected, setLeadManagerSelected] = useState<any>([]);
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<any[]>([]);
  const [propertyStageList, setPropertyStageList] = useState<any[]>([]);
  const [notIntReasonList, setNotIntReasonList] = useState<any[]>([]);
  const [lostReasonList, setLostReasonList] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [associateList, setAssociateList] = useState<any[]>([]);
  const [associateRoundrobinList, setAssociateRoundrobinList] = useState<any[]>(
    []
  );

  const [leadManagerList, setLeadManagerList] = useState<any[]>([]);

  const [uid, setUids] = useState<any>({});
  const location = useLocation<LocationState>();

  const { data, index } = location.state || {};

  const handleResize = () => {
    setMaxHeight(window.innerHeight * 0.8); // Adjust the multiplier as needed
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (user.organization_id) {
      const unsub = fetchLeadDistributions(user.organization_id, (data) =>
        setFilterData(data)
      );

      return () => {
        unsub();
      };
    }
  }, [user]);

  useEffect(() => {
    let leadManagerData: any = [];
    organizationUsers?.forEach((item: any) => {
      const fullNameEmail = `${item.user_first_name} ${item.user_last_name} - ${item.user_email}`;
      if (item.profile === "Lead Manager" || item.profile === "Team Lead") {
        leadManagerData.push(fullNameEmail);
      }
    });
    setLeadManagerList(leadManagerData); 
  }, [organizationUsers]);

  useEffect(() => {
    if (leadManagerSelected.length > 0) {
      let associateData: any = [];
      leadManagerSelected.forEach((item: any) => {
        if (item?.value !== "Select") {
          let l = item?.value?.split("-");
          if (l && l.length > 1) {
            let email = l[1].trim(); 

            organizationUsers?.forEach((item: any) => {
              const fullNameEmail = `${item.user_first_name} ${item.user_last_name} - ${item.user_email}`;
              if (item.reporting_to?.toLowerCase() === email.toLowerCase()) {
                associateData.push(fullNameEmail);
              }
            });
          }
        }
      });
      setAssociateRoundrobinList(associateData);
    }
  }, [leadManagerSelected, organizationUsers]);

  useEffect(() => {
    let data: any = [];
    let uids: { [key: string]: string } = {};
    organizationUsers?.forEach((item: any, index: number) => {
      data.push(
        `${item.user_first_name} ${item.user_last_name} -${item.user_email}`
      );
      uids[item.user_email] = item.uid;
    });

    setUids(uids);
    setAssociateList(data);
  }, [organizationUsers]);

  useEffect(() => {
    if (
      sourceSelected &&
      typeof sourceSelected === "object" &&
      sourceSelected.value &&
      projectSelected &&
      Array.isArray(projectSelected) &&
      projectSelected.length > 0
    ) {
      // Check if the selected source and project exist in filterData
      const isSourceAndProjectPresent = filterData.some((item) => {
        const isSourceMatch = item.source === sourceSelected.value;
        const isProjectMatch = projectSelected.some(
          (project) => item.project.includes(project.value)
        );
        return isSourceMatch && isProjectMatch;
      });
  
      if (isSourceAndProjectPresent) {
        setDuplicateSourceSelected(true);
        dispatcher(
          showSnackbarAction(
            `Lead distribution logic is already created for ${sourceSelected.value} and selected projects`,
            "error"
          )
        );
      } else {
        setDuplicateSourceSelected(false);
        console.log("Selected value does not exist in filterData for both source and project.");
      }
    }
    else if (
      sourceSelected &&
      typeof sourceSelected === "object" &&
      sourceSelected.value
    ) {
      const isSourcePresent = filterData.some(
        (item) => item.source === sourceSelected.value
      );
      if (isSourcePresent) {
        setDuplicateSourceSelected(true);
        dispatcher(
          showSnackbarAction(
            `Lead distribution logic is already created on ${sourceSelected.value}`,
            "error"
          )
        );
      } else {
        setDuplicateSourceSelected(false);
        console.log("Selected value does not exist in filterData");
      }
    }
    
  }, [sourceSelected, projectSelected]);
  


  useEffect(() => {
    const unsub = contactResources(
      user.organization_id,
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data)
    );
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data)
    );
    const unsubProperty = fetchpropertySubType(
      user.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );
    return () => {
      unsub();
      unsubConst();
      unsubProperty();
    };
    // eslint-disable-next-line
  }, []);

  const toggleAccordion = (id: string) => {
    if (id === expandedId) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };

  const onSubmitNormal = () => {
    let source: any = [];
    let project: any = [];
    let location: any = [];
    let budget: any = [];
    let propertyType: any = [];
    let usersList: any = [];
    let usersQueue: any = [];

    projectSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        project.push(item.value);
      }
    });
    if (
      sourceSelected &&
      typeof sourceSelected === "object" &&
      sourceSelected.value !== "Select"
    ) {
      source.push(sourceSelected.value);
    }
    locationSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        location.push(item.value);
      }
    });
    budgetSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        budget.push(item.value);
      }
    });
    propertyTypeSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        propertyType.push(item.value);
      }
    });
    // associateSelected.forEach((item: any) => {
    if (associateSelected?.value !== "Select") {
      let l = associateSelected?.value?.split("-");
      if (l && l.length > 1) {
        let email = l[1];
        usersList.push({ uid: uid[email], user_email: email });
        usersQueue.push(email);
      }
    }
    // });
    if (usersList.length === 0) {
      dispatcher(showSnackbarAction("Please select an associate", "error"));
    } else if (
      source.length === 0
    ) {
      dispatcher(showSnackbarAction("Please select a Lead Source", "error"));
    } else {
      setLoad(true);
      const leadDist_id = uuidv4();
      CreateLeadDistributionNormal(
        user.organization_id,
        source[0],
        project,
        location,
        budget,
        propertyType,
        usersList,
        usersQueue,
        "Normal",
        leadDist_id,
        dispatcher,
        (data) => setLoad(data),
        history
      );
    }
  };

  const onSubmitRoundrobin = () => {
    let source: any = [];
    let project: any = [];
    let location: any = [];
    let budget: any = [];
    let propertyType: any = [];
    let usersList: any = [];
    let usersQueue: any = [];
    let LeadManagerList: any = [];

    projectSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        project.push(item.value);
      }
    });
    if (
      sourceSelected &&
      typeof sourceSelected === "object" &&
      sourceSelected.value !== "Select"
    ) {
      source.push(sourceSelected.value);
    }
    locationSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        location.push(item.value);
      }
    });
    budgetSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        budget.push(item.value);
      }
    });
    propertyTypeSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        propertyType.push(item.value);
      }
    });
    leadManagerSelected.forEach((item: any) => {
      if (item?.value !== "Select") {
        let l = item?.value?.split("-");
        if (l && l.length > 1) {
          let email = l[1].trim();
          LeadManagerList.push({ uid: uid[email], user_email: email });
        }
      }
    });
    associateSelected.forEach((item: any) => {
      if (item?.value !== "Select") {
        let l = item?.value?.split("-");
        if (l && l.length > 1) {
          let email = l[1].trim();
          usersList.push({ uid: uid[email], user_email: email });
          usersQueue.push(email);
        }
      }
    });
    if (LeadManagerList.length === 0) {
      dispatcher(showSnackbarAction("Please select a Lead Manager", "error"));
    } else if (usersList.length === 0) {
      dispatcher(showSnackbarAction("Please select an Associate", "error"));
    } else if (
      source.length === 0
    ) {
      dispatcher(showSnackbarAction("Please select a Lead Source", "error"));
    } else {
      const leadDist_id = uuidv4();
      setLoad(true);
      CreateLeadDistributionRoundrobin(
        user.organization_id,
        source[0],
        project,
        location,
        budget,
        propertyType,
        usersList,
        usersQueue,
        LeadManagerList,
        "Roundrobin",
        leadDist_id,
        dispatcher,
        (data) => setLoad(data),
        history
      );
    }
  };
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: noData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  return (
    <div
      className="container-fluid p-4 d-flex flex-column gap-4"
      style={{
        minHeight: "100vh",
        height: "fit-content",
        ...containerStyles,
        marginTop: "4%",
        width: "100%",
        minWidth: "900px",
      }}
    >
      <div className="d-flex justify-content-between">
        <h5>Lead Distributor Logic Section</h5>
      </div>
      <div
        className="d-flex flex-column gap-4"
        style={{
          ...containerStyles,
          maxHeight: `${maxHeight}px`,
          overflowX: "hidden",
          overflowY: "scroll",
          paddingBottom: "5%",
        }}
      >
        <div className="card p-4 shadow rounded" style={cardContainerStyles}>
          <div className={styles.headerContainer}>
            <h6
              className={styles.heading}
            >{`Lead Distribution Logic : 1 - Normal Assignment`}</h6>
            <button
              className={styles.accordionButton}
              onClick={() => toggleAccordion("1")}
            >
              {expandedId === "1" ? (
                <i className="fas fa-chevron-up" />
              ) : (
                <i className="fas fa-chevron-down" />
              )}
            </button>
          </div>
          {expandedId === "1" && (
            <>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "100%",
                  padding: "20px",
                  marginTop: "20PX",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", // Proper box-shadow for web
                }}
              >
                <h6>Distribution Criteria</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridGap: "4px",
                    padding: "18px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "lead_source"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        //  isMulti
                        option={leadSourceList}
                        selectedValue={sourceSelected}
                        setSelectedValue={(value) => {
                          setSourceSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "project"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={projectsList}
                        selectedValue={projectSelected}
                        setSelectedValue={(value) => {
                          setProjectSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "location"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={locationsList}
                        selectedValue={locationSelected}
                        setSelectedValue={(value) => {
                          setlocationSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find((obj: any) => obj.label === "budget")
                          .value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={budgetsList}
                        selectedValue={budgetSelected}
                        setSelectedValue={(value) => {
                          setBudgetSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "property_type"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={propertyTypeList}
                        selectedValue={propertyTypeSelected}
                        setSelectedValue={(value) => {
                          setPropertyTypeSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <h6 style={{ marginTop: "20px" }}>Assigne Criteria</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridGap: "4px",
                    padding: "18px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div className={styles.divide}>
                    <Label label="Associate" required={false} />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        //  isMulti
                        option={associateList}
                        selectedValue={associateSelected}
                        setSelectedValue={(value) => {
                          setAssociateSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                  }}
                >
                <div
                      className={styles.buttonsBox1}
                      // style={{
                      //   backgroundColor: sourceDuplicateSelected
                      //     ? "red"
                      //     : "green",
                      // }}
                    >
                      <button
                        className={styles.apply1}
                        onClick={onSubmitNormal}
                        disabled={sourceDuplicateSelected}
                      >
                        Apply
                      </button>
                    </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="card p-4 shadow rounded" style={cardContainerStyles}>
          <div className={styles.headerContainer}>
            <h6
              className={styles.heading}
            >{`Lead Distribution Logic : 2 - Roundrobin Assignment`}</h6>
            <button
              className={styles.accordionButton}
              onClick={() => toggleAccordion("2")}
            >
              {expandedId === "2" ? (
                <i className="fas fa-chevron-up" />
              ) : (
                <i className="fas fa-chevron-down" />
              )}
            </button>
          </div>
          {expandedId === "2" && (
            <>
              <div
                style={{
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "100%",
                  padding: "20px",
                  marginTop: "20PX",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", // Proper box-shadow for web
                }}
              >
                <h6>Distribution Criteria</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridGap: "4px",
                    padding: "18px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "lead_source"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        // isMulti
                        option={leadSourceList}
                        selectedValue={sourceSelected}
                        setSelectedValue={(value) => {
                          setSourceSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "project"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={projectsList}
                        selectedValue={projectSelected}
                        setSelectedValue={(value) => {
                          setProjectSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "location"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={locationsList}
                        selectedValue={locationSelected}
                        setSelectedValue={(value) => {
                          setlocationSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find((obj: any) => obj.label === "budget")
                          .value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={budgetsList}
                        selectedValue={budgetSelected}
                        setSelectedValue={(value) => {
                          setBudgetSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "property_type"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={propertyTypeList}
                        selectedValue={propertyTypeSelected}
                        setSelectedValue={(value) => {
                          setPropertyTypeSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <h6 style={{ marginTop: "20px" }}>Assigne Criteria</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridGap: "4px",
                    padding: "18px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div className={styles.divide}>
                    <Label label="Lead Manager" required={false} />
                    <div style={{ width: "225px", color: "#000" }}>
                      {console.log("leadManagerList", associateRoundrobinList)}
                      <Dropdown
                        isMulti
                        option={leadManagerList}
                        selectedValue={leadManagerSelected}
                        setSelectedValue={(value) => {
                          setLeadManagerSelected(value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.divide}>
                    <Label label="Associate" required={false} />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={associateRoundrobinList}
                        selectedValue={associateSelected}
                        setSelectedValue={(value) => {
                          setAssociateSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                  }}
                >
                 <div className={styles.buttonsBox1}>
                      <button
                        className={styles.apply1}
                        disabled={sourceDuplicateSelected}
                        onClick={onSubmitRoundrobin}
                      >
                        Apply
                      </button>
                    </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    theme: state.theme.isLightMode,
    conatctHeader: state.contactHeaderList.data,
  };
};

export default connect(mapStateToProps)(LeadDistributorLogic);
