import React, { FunctionComponent, useState, useRef } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import styles from "./LeadDistributorLogic.module.css";
import Lottie from "react-lottie";
import noData from "../../Assets/animations/no-data.json";
import { themeColors } from "../../Components/theme";
import Label from "../../Components/Label";
import Dropdown from "../../Components/DropDown/Dropdown";
import {
  contactResources,
  CreateLeadDistributionNormal,
  CreateLeadDistributionRoundrobin,
  editLeadDistributionNormal,
  editLeadDistributionRoundrobin,
  fetchConstants,
  fetchLeadDistributions,
  fetchpropertySubType,
} from "../../Services/contacts";
import { showSnackbarAction } from "../../Redux/actions";
import { useLocation } from "react-router-dom";
import Firebase from "firebase/app";

interface LocationState {
  data: any; // Type this according to your actual data structure
  index: number;
}

type props = {
  history?: any;
  user?: any;
  organizationUsers?: any;
  theme?: any;
  conatctHeader?: any;
};
const LeadDistributorLogic: FunctionComponent<props> = ({
  history,
  user,
  organizationUsers,
  theme,
  conatctHeader,
}) => {
  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.8);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const dispatcher = useDispatch();

  const [filterData, setFilterData] = useState<any[]>([]);
  const [normalLocationSelected, setNormalLocationSelected] = useState<any>([]);
  const [roundrobinlocationSelected, setRoundrobinlocationSelected] =
    useState<any>([]);
  const [roundrobinSourceSelected, setRoundrobinSourceSelected] =
    useState<any>();
  // const [roundrobinSourceDuplicateSelected, setRoundrobinDuplicateSourceSelected] =useState<boolean>(false);
  const [normalSourceSelected, setNormalSourceSelected] = useState<any>();
  const [sourceDuplicateSelected, setDuplicateSourceSelected] =
    useState<boolean>(false);
  const [roundrobinProjectSelected, setRoundrobinProjectSelected] =
    useState<any>([]);
  const [normalprojectSelected, setNormalProjectSelected] = useState<any>([]);
  const [roundrobinBudgetSelected, setRoundrobinBudgetSelected] = useState<any>(
    []
  );
  const [normalBudgetSelected, setNormalBudgetSelected] = useState<any>([]);
  const [roundrobinPropertyTypeSelected, setRoundrobinPropertyTypeSelected] =
    useState<any>([]);
  const [normalPropertyTypeSelected, setNormalPropertyTypeSelected] =
    useState<any>([]);
  const [leadManagerSelected, setLeadManagerSelected] = useState<any>([]);
  const [associateRoundrobinList, setAssociateRoundrobinList] = useState<any[]>(
    []
  );
  const [roundrobinAssociateSelected, setRoundrobinAssociateSelected] = useState<any>([]);
  const [normalAssociateSelected, setNormalAssociateSelected] = useState<any>([]);
  const [associateList, setAssociateList] = useState<any[]>([]);
  const [leadManagerList, setLeadManagerList] = useState<any[]>([]);
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<any[]>([]);
  const [propertyStageList, setPropertyStageList] = useState<any[]>([]);
  const [notIntReasonList, setNotIntReasonList] = useState<any[]>([]);
  const [lostReasonList, setLostReasonList] = useState<any[]>([]);
  const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);

  const [uid, setUids] = useState<any>({});
  const location = useLocation<LocationState>();
  const [load, setLoad] = useState(false);

  // Use optional chaining to ensure `state` exists
  const { data, index } = location.state || {};

  const handleResize = () => {
    // Update maxHeight when the window is resized
    setMaxHeight(window.innerHeight * 0.8); // Adjust the multiplier as needed
  };
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (user.organization_id) {
      const unsub = fetchLeadDistributions(user.organization_id, (data) =>
        setFilterData(data)
      );
      return () => {
        unsub();
      };
    }
  }, [user]);

  useEffect(() => {
    let leadManagerData: any = [];
    organizationUsers?.forEach((item: any) => {
      const fullNameEmail = `${item.user_first_name} ${item.user_last_name} - ${item.user_email}`;
      if (item.profile === "Lead Manager" || item.profile === "Team Lead") {
        leadManagerData.push(fullNameEmail);
      }
    });
    setLeadManagerList(leadManagerData); // set data for Lead Manager
  }, [organizationUsers]);

  useEffect(() => {
    if (leadManagerSelected.length > 0) {
      let associateData: any = [];
      leadManagerSelected.forEach((item: any) => {
        if (item?.value !== "Select") {
          let email: any;
          if (item?.value.includes("-")) {
            let l = item?.value?.split("-");
            if (l && l.length > 1) {
              email = l[1].trim(); 
            }
          } else {
            email = item?.value.trim(); 
          }
          organizationUsers?.forEach((orgUser: any) => {
            const fullNameEmail = `${orgUser.user_first_name} ${orgUser.user_last_name} - ${orgUser.user_email}`;
            if (orgUser.reporting_to?.toLowerCase() === email.toLowerCase()) {
              associateData.push(fullNameEmail);
            }
          });
        }
      });
      setAssociateRoundrobinList(associateData);
    }
  }, [leadManagerSelected, organizationUsers]);

  useEffect(() => {
    let data: any = [];
    let uids: { [key: string]: string } = {};
    organizationUsers?.forEach((item: any, index: number) => {
      data.push(
        `${item.user_first_name} ${item.user_last_name} -${item.user_email}`
      );
      uids[item.user_email] = item.uid;
    });

    setUids(uids);
    setAssociateList(data);
  }, [organizationUsers]);

  // useEffect(() => {
  //   if (
  //     roundrobinSourceSelected &&
  //     typeof roundrobinSourceSelected === "object" &&
  //     roundrobinSourceSelected.value
  //   ) {
  //     const isSourcePresent = filterData.some(
  //       (item) => item.source === roundrobinSourceSelected.value
  //     );
  //     if (isSourcePresent) {
  //       setDuplicateSourceSelected(true);
  //       dispatcher(
  //         showSnackbarAction(
  //           `Lead distribution logic is already created on ${roundrobinSourceSelected.value}`,
  //           "error"
  //         )
  //       );
  //     } else {
  //       setDuplicateSourceSelected(false);
  //       console.log("Selected value does not exist in filterData");
  //     }
  //   }
  // }, [roundrobinSourceSelected]);

  useEffect(() => {
    if (
      roundrobinSourceSelected &&
      typeof roundrobinSourceSelected === "object" &&
      roundrobinSourceSelected.value &&
      roundrobinSourceSelected &&
      Array.isArray(roundrobinProjectSelected) &&
      roundrobinProjectSelected.length > 0
    ) {
      // Check if the selected source and project exist in filterData
      const isSourceAndProjectPresent = filterData.some((item) => {
        const isSourceMatch = item.source === roundrobinSourceSelected.value;
        const isProjectMatch = roundrobinProjectSelected.some(
          (project) => item.project.includes(project.value)
        );
        return isSourceMatch && isProjectMatch;
      });
  
      if (isSourceAndProjectPresent) {
        setDuplicateSourceSelected(true);
        dispatcher(
          showSnackbarAction(
            `Lead distribution logic is already created for ${roundrobinSourceSelected.value} and selected projects`,
            "error"
          )
        );
      } else {
        setDuplicateSourceSelected(false);
        console.log("Selected value does not exist in filterData for both source and project.");
      }
    }
    else if (
      roundrobinSourceSelected &&
      typeof roundrobinSourceSelected === "object" &&
      roundrobinSourceSelected.value
    ) {
      const isSourcePresent = filterData.some(
        (item) => item.source === roundrobinSourceSelected.value
      );
      if (isSourcePresent) {
        setDuplicateSourceSelected(true);
        dispatcher(
          showSnackbarAction(
            `Lead distribution logic is already created on ${roundrobinSourceSelected.value}`,
            "error"
          )
        );
      } else {
        setDuplicateSourceSelected(false);
        console.log("Selected value does not exist in filterData");
      }
    }
    
  }, [roundrobinSourceSelected, roundrobinProjectSelected]);

  useEffect(() => {
    if (
      normalSourceSelected &&
      typeof normalSourceSelected === "object" &&
      normalSourceSelected.value &&
      normalSourceSelected &&
      Array.isArray(normalprojectSelected) &&
      normalprojectSelected.length > 0
    ) {
      // Check if the selected source and project exist in filterData
      const isSourceAndProjectPresent = filterData.some((item) => {
        const isSourceMatch = item.source === normalSourceSelected.value;
        const isProjectMatch = normalprojectSelected.some(
          (project) => item.project.includes(project.value)
        );
        return isSourceMatch && isProjectMatch;
      });
  
      if (isSourceAndProjectPresent) {
        setDuplicateSourceSelected(true);
        dispatcher(
          showSnackbarAction(
            `Lead distribution logic is already created for ${normalSourceSelected.value} and selected projects`,
            "error"
          )
        );
      } else {
        setDuplicateSourceSelected(false);
        console.log("Selected value does not exist in filterData for both source and project.");
      }
    }
    else if (
      normalSourceSelected &&
      typeof normalSourceSelected === "object" &&
      normalSourceSelected.value
    ) {
      const isSourcePresent = filterData.some(
        (item) => item.source === normalSourceSelected.value
      );
      if (isSourcePresent) {
        setDuplicateSourceSelected(true);
        dispatcher(
          showSnackbarAction(
            `Lead distribution logic is already created on ${normalSourceSelected.value}`,
            "error"
          )
        );
      } else {
        setDuplicateSourceSelected(false);
        console.log("Selected value does not exist in filterData");
      }
    }
    
  }, [normalSourceSelected, normalprojectSelected]);

  useEffect(() => {
    if (data) {
      if (user.organization_id) {
        // fetchLeadDistributionsEdit(user.organization_id, (data:any) =>
        //   setFilterData(data)
        // );
      }
      let project = data.project.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
              value: item,
              label: "Team Leader",
            }
          : { value: item, label: item }
      );
      let location = data.location.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
              value: item,
              label: "Team Leader",
            }
          : { value: item, label: item }
      );
      let budget = data.budget.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
              value: item,
              label: "Team Leader",
            }
          : { value: item, label: item }
      );
      let property_type = data.property_type.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
              value: item,
              label: "Team Leader",
            }
          : { value: item, label: item }
      );
      // let source = data.source.map((item: any, index: number) =>
      //   item === "Team Lead"
      //     ? {
      //         value: item,
      //         label: "Team Leader",
      //       }
      //     : { value: item, label: item }
      // );
      let users = data.users.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
              value: item,
              label: "Team Leader",
            }
          : { value: item, label: item }
      );
      let leadManager = data?.leadManager_users?.map((item: any, index: number) =>
        item === "Team Lead"
          ? {
              value: item?.user_email,
              label: "Team Leader",
            }
          : { value: item?.user_email, label: item?.user_email }
      );
      if (data.distribution_type === "Normal") {
        setNormalLocationSelected(location);
        setNormalProjectSelected(project);
        setNormalBudgetSelected(budget);
        setNormalPropertyTypeSelected(property_type);
        setNormalSourceSelected({ value: data.source, label: data.source });
        setNormalAssociateSelected(users);
      } 
      else if (data.distribution_type === "Roundrobin") {
        setRoundrobinlocationSelected(location);
        setRoundrobinProjectSelected(project);
        setRoundrobinBudgetSelected(budget);
        setRoundrobinPropertyTypeSelected(property_type);
        setRoundrobinSourceSelected({ value: data.source, label: data.source });
        setRoundrobinAssociateSelected(users);
        setLeadManagerSelected(leadManager);
      }
    }
  }, []);

  useEffect(() => {
    const unsub = contactResources(
      user.organization_id,
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data)
    );
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data)
    );
    const unsubProperty = fetchpropertySubType(
      user.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );
    return () => {
      unsub();
      unsubConst();
      unsubProperty();
    };
    // eslint-disable-next-line
  }, []);

  const toggleAccordion = (id: string) => {
    if (id === expandedId) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };

  useEffect(() => {
    if (data && data?.distribution_type === "Normal") {
      setExpandedId("1");
    } else if (data && data?.distribution_type === "Roundrobin") {
      setExpandedId("2");
    } else {
      setExpandedId(null);
    }
  }, [data, index]);

  const onEditNormal = () => {
    let source: any = [];
    let project: any = [];
    let location: any = [];
    let budget: any = [];
    let propertyType: any = [];
    let usersList: any = [];
    let usersQueue: any = [];
    normalprojectSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        project.push(item.value);
      }
    });
    if (
      normalSourceSelected &&
      typeof normalSourceSelected === "object" &&
      normalSourceSelected.value !== "Select"
    ) {
      source.push(normalSourceSelected.value);
    }
    normalLocationSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        location.push(item.value);
      }
    });
    normalBudgetSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        budget.push(item.value);
      }
    });
    normalPropertyTypeSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        propertyType.push(item.value);
      }
    });
    if (Array.isArray(normalAssociateSelected)) {
      normalAssociateSelected.forEach((item: any) => {
        if (item.value !== "Select") {
          let l = item.value?.split("-");
          let email = l && l.length > 1 ? l[1].trim() : item.value; // Extract email or use value directly if no "-"
          
          if (uid[email.trim()] !== undefined) {
            usersList.push({
              uid: uid[email.trim()], 
              user_email: email.trim(),
            });
            usersQueue.push(email);
          }
        }
      });
    } else {
      if (normalAssociateSelected?.value !== "Select") {
        let l = normalAssociateSelected.value?.split("-");
        let email = l && l.length > 1 ? l[1].trim() : normalAssociateSelected.value; // Extract email or use value directly if no "-"
        if (uid[email.trim()] !== undefined) {
          usersList.push({
            uid: uid[email.trim()],
            user_email: email.trim(),
          });
          usersQueue.push(email);
        }
      }
    }
    if (usersList.length === 0) {
      dispatcher(showSnackbarAction("Please select an Associate", "error"));
    } else if (source.length === 0) {
      dispatcher(showSnackbarAction("Please select a Lead Source", "error"));
    } else {
      setLoad(true);
      const normalizeUsersData = (users:any) => {
        return users.map((user:any) => {
            const userEmail = typeof user === 'string' ? user : user.user_email;
            return {
                user_email: userEmail,
                uid: uid[userEmail] || '' // Look up UID from the mapping
            };
        });
    };
      if (index !== undefined) {
        filterData[index] = {
          source: source[0],
          project: [].concat(...project),
          location: [].concat(...location),
          budget: [].concat(...budget),
          property_type: [].concat(...propertyType),
          users: normalizeUsersData(usersList),
          usersQueue: [].concat(...usersQueue),
          distribution_type: "Normal",
          created_at: Firebase.firestore.Timestamp.now(),
          modify_at: Firebase.firestore.Timestamp.now()
        };
        editLeadDistributionNormal(
          uid,
          user.organization_id,
          filterData,
          dispatcher,
          (data) => setLoad(data),
          history
        );
      }
    }
  };

  console.log("users id ",uid)

  const onEditRoundrobin = () => {
    let source: any = [];
    let project: any = [];
    let location: any = [];
    let budget: any = [];
    let propertyType: any = [];
    let usersList: any = [];
    let LeadManagerList: any = [];
    let usersQueue: any = [];

    roundrobinProjectSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        project.push(item.value);
      }
    });
    if (
      roundrobinSourceSelected &&
      typeof roundrobinSourceSelected === "object" &&
      roundrobinSourceSelected.value !== "Select"
    ) {
      source.push(roundrobinSourceSelected.value);
    }
    roundrobinlocationSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        location.push(item.value);
      }
    });
    roundrobinBudgetSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        budget.push(item.value);
      }
    });
    roundrobinPropertyTypeSelected.forEach((item: any) => {
      if (item.value !== "Select") {
        propertyType.push(item.value);
      }
    });
    
    if (Array.isArray(leadManagerSelected)) {
      leadManagerSelected.forEach((item: any) => {
        console.log("uid if array lead", uid);
        if (item.value !== "Select") {
          let l = item.value?.split("-"); // Split based on "-"
          let email = l && l.length > 1 ? l[1].trim() : item.value; // Get the email or use the value directly if no dash exists
          
          if (uid[email.trim()] !== undefined) {
            LeadManagerList.push({ uid: uid[email.trim()], user_email: email.trim() });
          }
        }
      });
    } else {
      if (leadManagerSelected?.value !== "Select") {
        let l = leadManagerSelected.value?.split("-"); // Split based on "-"
        let email = l && l.length > 1 ? l[1].trim() : leadManagerSelected.value; // Get the email or use the value directly if no dash exists
    
        if (uid[email.trim()] !== undefined) {
          LeadManagerList.push({ uid: uid[email.trim()], user_email: email.trim() });
        }
      }
    }

    if (Array.isArray(roundrobinAssociateSelected)) {
      roundrobinAssociateSelected.forEach((item: any) => {
        if (item.value !== "Select") {
          let l = item.value?.split("-");
          let email = l && l.length > 1 ? l[1].trim() : item.value; // Extract email or use value directly if no "-"
          
          if (uid[email.trim()] !== undefined) {
            usersList.push({
              uid: uid[email.trim()], 
              user_email: email.trim(),
            });
            usersQueue.push(email);
          }
        }
      });
    } else {
      if (roundrobinAssociateSelected?.value !== "Select") {
        let l = roundrobinAssociateSelected.value?.split("-");
        let email = l && l.length > 1 ? l[1].trim() : roundrobinAssociateSelected.value; // Extract email or use value directly if no "-"
        
        if (uid[email.trim()] !== undefined) {
          usersList.push({
            uid: uid[email.trim()],
            user_email: email.trim(),
          });
          usersQueue.push(email);
        }
      }
    }
    console.log("Edit usersList", usersList);
    if (LeadManagerList.length === 0) {
      dispatcher(showSnackbarAction("Please select a Lead Manager", "error"));
    } else if (usersList.length === 0) {
      dispatcher(showSnackbarAction("Please select an Associate", "error"));
    } else if (source.length === 0) {
      dispatcher(showSnackbarAction("Please select a Lead Source", "error"));
    } else {
      setLoad(true);
      const normalizeUsersData = (users:any) => {
            return users.map((user:any) => {
                const userEmail = typeof user === 'string' ? user : user.user_email;
                return {
                    user_email: userEmail,
                    uid: uid[userEmail] || '' // Look up UID from the mapping
                };
            });
        };
      const normalizeLeadManagerData = (leadManagerUsers:any) => {
          return leadManagerUsers.map((user:any) => {
              const userEmail = typeof user === 'string' ? user : user.user_email;
              return {
                  user_email: userEmail,
                  uid: uid[userEmail] || '' // Look up UID from the mapping
              };
          });
      };

      if (index !== undefined) {
        filterData[index] = {
          source: source[0],
          project: [].concat(...project),
          location: [].concat(...location),
          budget: [].concat(...budget),
          property_type: [].concat(...propertyType),
          users: normalizeUsersData(usersList),
          leadManager_users:normalizeLeadManagerData(LeadManagerList),
          usersQueue: [].concat(...usersQueue),
          distribution_type: "Roundrobin",
          created_at: Firebase.firestore.Timestamp.now(),
          modify_at: Firebase.firestore.Timestamp.now()
        };
        editLeadDistributionRoundrobin(
          uid,
          user.organization_id,
          filterData,
          dispatcher,
          (data) => setLoad(data),
          history
        );
      }
    }
  };
  console.log("leadManagerSelectedleadManagerSelected",leadManagerSelected)

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: noData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  return (
    <div
      className="container-fluid p-4 d-flex flex-column gap-4"
      style={{
        minHeight: "100vh",
        height: "fit-content",
        ...containerStyles,
        marginTop: "4%",
        width: "100%",
        minWidth: "900px",
      }}
    >
      <div className="d-flex justify-content-between">
        <h5>Lead Distributor Logic Section</h5>
      </div>
      <div
        className="d-flex flex-column gap-4"
        style={{
          ...containerStyles,
          maxHeight: `${maxHeight}px`,
          overflowX: "hidden",
          overflowY: "scroll",
          paddingBottom: "5%",
        }}
      >
        <div className="card p-4 shadow rounded" style={cardContainerStyles}>
          <div className={styles.headerContainer}>
            <h6
              className={styles.heading}
            >{`Lead Distribution Logic : 1 - Normal Assignment`}</h6>
            <button
              className={styles.accordionButton}
              onClick={() => toggleAccordion("1")}
            >
              {expandedId === "1" ? (
                <i className="fas fa-chevron-up" />
              ) : (
                <i className="fas fa-chevron-down" />
              )}
            </button>
          </div>
          {expandedId === "1" && (
            <>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "100%",
                  padding: "20px",
                  marginTop: "20PX",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", // Proper box-shadow for web
                }}
              >
                <h6>Distribution Criteria</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridGap: "4px",
                    padding: "18px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "lead_source"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        //  isMulti
                        option={leadSourceList}
                        selectedValue={normalSourceSelected}
                        setSelectedValue={(value) => {
                          setNormalSourceSelected(value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "project"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={projectsList}
                        selectedValue={normalprojectSelected}
                        setSelectedValue={(value) => {
                          setNormalProjectSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "location"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={locationsList}
                        selectedValue={normalLocationSelected}
                        setSelectedValue={(value) => {
                          setNormalLocationSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find((obj: any) => obj.label === "budget")
                          .value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={budgetsList}
                        selectedValue={normalBudgetSelected}
                        setSelectedValue={(value) => {
                          setNormalBudgetSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "property_type"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={propertyTypeList}
                        selectedValue={normalPropertyTypeSelected}
                        setSelectedValue={(value) => {
                          setNormalPropertyTypeSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <h6 style={{ marginTop: "20px" }}>Assigne Criteria</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridGap: "4px",
                    padding: "18px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div className={styles.divide}>
                    <Label label="Associate" required={false} />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        //  isMulti
                        option={associateList}
                        selectedValue={normalAssociateSelected}
                        setSelectedValue={(value) => {
                          setNormalAssociateSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                  }}
                >
                  <div className={styles.buttonsBox1}>
                    <button
                      className={styles.apply1}
                      onClick={onEditNormal}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="card p-4 shadow rounded" style={cardContainerStyles}>
          <div className={styles.headerContainer}>
            <h6
              className={styles.heading}
            >{`Lead Distribution Logic : 2 - Roundrobin Assignment`}</h6>
            <button
              className={styles.accordionButton}
              onClick={() => toggleAccordion("2")}
            >
              {expandedId === "2" ? (
                <i className="fas fa-chevron-up" />
              ) : (
                <i className="fas fa-chevron-down" />
              )}
            </button>
          </div>
          {expandedId === "2" && (
            <>
              <div
                style={{
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "100%",
                  padding: "20px",
                  marginTop: "20PX",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", // Proper box-shadow for web
                }}
              >
                <h6>Distribution Criteria</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridGap: "4px",
                    padding: "18px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "lead_source"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        // isMulti
                        option={leadSourceList}
                        selectedValue={roundrobinSourceSelected}
                        setSelectedValue={(value) => {
                          setRoundrobinSourceSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "project"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={projectsList}
                        selectedValue={roundrobinProjectSelected}
                        setSelectedValue={(value) => {
                          setRoundrobinProjectSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "location"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={locationsList}
                        selectedValue={roundrobinlocationSelected}
                        setSelectedValue={(value) => {
                          setRoundrobinlocationSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find((obj: any) => obj.label === "budget")
                          .value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={budgetsList}
                        selectedValue={roundrobinBudgetSelected}
                        setSelectedValue={(value) => {
                          setRoundrobinBudgetSelected(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.divide}>
                    <Label
                      label={
                        conatctHeader.find(
                          (obj: any) => obj.label === "property_type"
                        ).value
                      }
                      required={false}
                    />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={propertyTypeList}
                        selectedValue={roundrobinPropertyTypeSelected}
                        setSelectedValue={(value) => {
                          setRoundrobinPropertyTypeSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <h6 style={{ marginTop: "20px" }}>Assigne Criteria</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridGap: "4px",
                    padding: "18px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div className={styles.divide}>
                    <Label label="Lead Manager" required={false} />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={leadManagerList}
                        selectedValue={leadManagerSelected}
                        setSelectedValue={(value) => {
                          setLeadManagerSelected(value);
                          setRoundrobinAssociateSelected([])
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.divide}>
                    <Label label="Associate" required={false} />
                    <div style={{ width: "225px", color: "#000" }}>
                      <Dropdown
                        isMulti
                        option={associateRoundrobinList}
                        selectedValue={roundrobinAssociateSelected}
                        setSelectedValue={(value) => {
                          setRoundrobinAssociateSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                  }}
                >
                  <div className={styles.buttonsBox1}>
                    <button
                      className={styles.apply1}
                      onClick={onEditRoundrobin}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="card p-4 shadow rounded" style={cardContainerStyles}>
          <div className={styles.headerContainer}>
            <h6
              className={styles.heading}
            >{`Lead Distribution Logic : 3 - Reassign Unattended`}</h6>
            <button
              className={styles.accordionButton}
              onClick={() => toggleAccordion("3")}
            >
              {expandedId === "3" ? (
                <i className="fas fa-chevron-up" />
              ) : (
                <i className="fas fa-chevron-down" />
              )}
            </button>
          </div>
          {expandedId === "3" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Not available
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    theme: state.theme.isLightMode,
    conatctHeader: state.contactHeaderList.data,
  };
};

export default connect(mapStateToProps)(LeadDistributorLogic);
