import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  FormControlLabel,
  Button,
  Box,
  Grid,
  Typography,
  Container,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { createDaysTimeSlots, fetchDaysTimeSlots } from "../../Services/organizations";
import Loading from "../../Components/Loading/Loading";
import { showSnackbarAction } from "../../Redux/actions";

type Props = {
  history: any;
  organizationId: any;
  apiHeaders: any;
  theme: any;
};

type ScheduleItem = {
  day: string;
  closed: boolean;
  opensAt: string;
  closesAt: string;
};

const DaysConfiguration: FunctionComponent<Props> = ({
  history,
  organizationId,
  apiHeaders,
  theme,
}) => {
   const dispatcher = useDispatch();
   const[load,setLoad]=useState(false);
  const [schedule, setSchedule] = useState<ScheduleItem[]>([
    { day: "Monday", closed: false, opensAt: "09:00", closesAt: "19:00" },
    { day: "Tuesday", closed: false, opensAt: "09:00", closesAt: "19:00" },
    { day: "Wednesday", closed: false, opensAt: "09:00", closesAt: "19:00" },
    { day: "Thursday", closed: false, opensAt: "09:00", closesAt: "19:00" },
    { day: "Friday", closed: false, opensAt: "09:00", closesAt: "19:00" },
    { day: "Saturday", closed: true, opensAt: "", closesAt: "" },
    { day: "Sunday", closed: true, opensAt: "", closesAt: "" },
  ]);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.58);

  const handleResize = () => {
    setMaxHeight(window.innerHeight * 0.8);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (organizationId) {
      const unsubscribe = fetchDaysTimeSlots(organizationId, (data) => {
        if (data.length > 0) {
          setSchedule(data);
        }
         else {
          console.log("No valid daysTimeSlots found, retaining default schedule.");
        }
      });
      return () => unsubscribe();
    }
  }, [organizationId]);

    
  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[index] = { ...updatedSchedule[index], [field]: value };
    setSchedule(updatedSchedule);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[index].closed = !updatedSchedule[index].closed;
    if (updatedSchedule[index].closed) {
      updatedSchedule[index].opensAt = "";
      updatedSchedule[index].closesAt = "";
    } else {
      updatedSchedule[index].opensAt = "";
      updatedSchedule[index].closesAt = "";
    }
    setSchedule(updatedSchedule);
  };

  const handleSave = () => {
    for (const item of schedule) {
      if (!item.closed && (!item.opensAt || !item.closesAt)) {
        dispatcher(showSnackbarAction(`Please provide valid opening and closing times for ${item.day}.`, "error"));
        return; 
      }
    }
    setLoad(true);
    createDaysTimeSlots(
      organizationId,
      schedule,
      dispatcher,
      (val) => setLoad(val),
    )
  };


  return (
    <>
   <Box>
   {load && <Loading />}
   </Box>
    <Container
      maxWidth="lg"
      sx={{
        mt: 8,
        mb: 8,
        p: 4,
        bgcolor: "background.paper",
        boxShadow: 3,
        borderRadius: 4,
        textAlign: "center",
        border: "1px solid #e0e0e0",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Weekly Schedule Configuration
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 6, color: "text.secondary" }}>
        Customize your organization's weekly schedule. You can set operating
        hours or mark days as closed.
      </Typography>
      <Box
        sx={{
          maxHeight: `${maxHeight}px`,
          overflowY: "auto",
          padding: 1,
        }}
      >
        {schedule?.map((item, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            alignItems="center"
            sx={{
              mb: 1,
              p: 1,
            }}
          >
            <Grid
              item
              xs={12}
              sm={1}
            ></Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                display: 'flex',
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.closed}
                    onChange={() => handleCheckboxChange(index)}
                    aria-label={`${item.day} closed checkbox`}
                    sx={{
                      color: item.closed ? "red" : "green",
                      "& .MuiSvgIcon-root": { fontSize: 18 },
                      marginRight: "8px",
                    }}
                  />
                }
                label={
                  <Typography variant="body2"   sx={{
                    whiteSpace: "nowrap",
                    color: item.closed ? "red" : "green",
                  }}>
                    {item.closed?`${item.day} Closed`:`${item.day} Open`}
                  </Typography>
                }
              />
            </Grid>
            {!item.closed && (
              <>
                <Grid item xs={6} sm={4}>
                  <TextField
                    type="time"
                    label="Opens At"
                    value={item.opensAt}
                    onChange={(e) =>
                      handleInputChange(index, "opensAt", e.target.value)
                    }
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: 1,
                        padding: "0px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextField
                    type="time"
                    label="Closes At"
                    value={item.closesAt}
                    onChange={(e) =>
                      handleInputChange(index, "closesAt", e.target.value)
                    }
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: 1,
                        padding: "0px",
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 4, mt: 2 }}>
        <button
          style={{
            fontSize: "12px",
            fontWeight: 600,
            backgroundColor: "#272944",
            color:"white",
            border: "solid 0px",
            borderRadius: "0.25rem",
            height: "34px",
            width:"80px"
          }}
          onClick={handleSave}
        >
          Save
        </button>
      </Box>
    </Container>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  organizationId: state.organization.id,
  apiHeaders: state.apiHeaderList.data,
  theme: state.theme.isLightMode,
});

export default connect(mapStateToProps)(DaysConfiguration);
